import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import ClickDataStoreSagaHandlers from '../../sagas/data/ClickDataStore'

const sliceName = 'clicksStore'

const ClickDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  ClickDataStoreSagaHandlers
)

export default ClickDataStoreSlice
