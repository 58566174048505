import { combineReducers } from 'redux'
import UserDataStoreSlice from '../../slices/data/UserDataStoreSlice'
import LinkDataStoreSlice from '../../slices/data/LinkDataStoreSlice'
import ClickDataStoreSlice from '../../slices/data/ClickDataStoreSlice'
import LanguageDataStoreSlice from '../../slices/data/LanguageDataStoreSlice'
import ApiKeyDataStoreSlice from '../../slices/data/ApiKeyDataStoreSlice'
import GloballyExcludedClickerDataStoreSlice from '../../slices/data/GloballyExcludedClickerDataStoreSlice'

/**
 * Stores global state(state used by multiple experiences).
 * It is the core of the store normalization process: other experiences that use this state, reference items from this
 * store, without duplicating the same data.
 * @type {Reducer<CombinedState<{}>>}
 */
const DataStore = combineReducers({
  users: UserDataStoreSlice.reducer,
  links: LinkDataStoreSlice.reducer,
  clicks: ClickDataStoreSlice.reducer,
  languages: LanguageDataStoreSlice.reducer,
  apiKeys: ApiKeyDataStoreSlice.reducer,
  globallyExcludedClickers: GloballyExcludedClickerDataStoreSlice.reducer
})

export default DataStore
