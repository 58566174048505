import { normalize } from 'normalizr'

class ListFetchResult {
  constructor (totalNrOfItems, items) {
    if (!items || !Array.isArray(items)) {
      throw new Error(`[ListFetchResult] Items should be an array: received ${items}`)
    }

    this.totalNrOfItems = totalNrOfItems
    this.items = items
  }

  /**
   * Converts list fetch result into a normalized form, used by different store types: data-stores and experience-stores.
   * @param normalizationSchema {object}: normalization schema of `this.items`
   * @param RESTItemsToStoreItems {object}: conversion mapping for converting the normalized REST items into store-items.
   * The mapping keys are the entity names and the values are their associated REST-to-store-item handlers.
   * @returns {{result: string[], entities: {}, totalNrOfItems: number}}:
   *  - `result` contains the ids of `this.items`
   *  - `entities` contains the normalized entities mapped by their "entity name"
   *  - `totalNrOfItems` contains the total number of items on the server(`this.items`). It is useful in list management.
   */
  toStoreValues (normalizationSchema, RESTItemsToStoreItems) {
    const normalizedData = normalize(this.items, [normalizationSchema])
    return ({
      totalNrOfItems: this.totalNrOfItems,
      result: normalizedData.result,
      entities: Object
        .keys(normalizedData.entities)
        // convert entities(normalized rest items) to store items
        .reduce(
          (entities, entityCategoryName) => {
            if (normalizedData.entities[entityCategoryName]) {
              // if entities from this category were extracted during normalization, process them
              entities[entityCategoryName] = typeof RESTItemsToStoreItems[entityCategoryName] === 'function'
                // convert entities to store items if a converter exists for this entity category
                ? Object
                  .keys(normalizedData.entities[entityCategoryName])
                  .reduce(
                    (convertedEntities, entityId) => {
                      convertedEntities[entityId] = RESTItemsToStoreItems[entityCategoryName](
                        normalizedData.entities[entityCategoryName][entityId]
                      ).toJSON()
                      return convertedEntities
                    },
                    {}
                  )
                // return entities as they are if no converter exists for this entity category
                : normalizedData.entities[entityCategoryName]
            }

            return entities
          },
          {}
        )
    })
  }

  /**
   * @param axiosResponse {AxiosResponse<object[]>}
   * @returns {ListFetchResult}
   */
  static FromAxiosResponse (axiosResponse) {
    const { totalCount, items } = axiosResponse.data

    return new ListFetchResult(
      totalCount,
      items
    )
  }
}

export default ListFetchResult
