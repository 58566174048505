import { makeActionCreator } from '../../modules/utilities/store'
import GLOBAL_TYPES from '../types/Global'

const GlobalActions = {
  /**
   * Resets the entire redux store.
   * @returns {{ type: string, data: { } }}
   */
  resetStore: makeActionCreator(GLOBAL_TYPES.RESET_STORE)
}

export default GlobalActions
