const selectLinks = state => state.data.links

const selectLinkById = linkId => state => state.data.links[linkId]

const LinkDataStoreSelectors = {
  links: selectLinks,
  linkById: selectLinkById
}

export default LinkDataStoreSelectors
