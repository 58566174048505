import { useMemo } from 'react'
import useNormalizedApiKeys from './useNormalizedApiKeys'

/**
 * Returns requested apiKey(s) from state.data.apiKeys in a normalized fashion.
 * @param apiKeyIds {string | string[]}: id of requested apiKeys.
 * @param asMap {boolean}: when multiple apiKeys are requested, return apiKeys as an object mapping apiKeys by their id.
 * @param deNormalizationConfig {{}}:
 * specify which related models are merged into the apiKey object. Default config returns the same result as
 * useNormalizedApiKeys.
 * @param fetchAllEnabled {boolean}: enable fetching of all apiKeys, when no apiKey id is passed.
 * @returns {StoreApiKey | StoreApiKey[]}
 */
const useApiKeys = (
  apiKeyIds = null,
  asMap = false,
  deNormalizationConfig = {},
  fetchAllEnabled = false
) => {
  const normalizedApiKeys = useNormalizedApiKeys(apiKeyIds, asMap, fetchAllEnabled)

  return useMemo(
    () => {
      const populateApiKey = apiKey => apiKey

      return normalizedApiKeys && (
        typeof apiKeyIds === 'string' || typeof apiKeyIds === 'number'
          ? populateApiKey(normalizedApiKeys)
          : asMap
            ? Object.keys(normalizedApiKeys)
              .reduce((apiKeys, apiKeyId) => {
                apiKeys[apiKeyId] = populateApiKey(normalizedApiKeys[apiKeyId])
                return apiKeys
              }, {})
            : normalizedApiKeys.map(populateApiKey)
      )
    },
    [normalizedApiKeys, apiKeyIds, asMap]
  )
}

export default useApiKeys
