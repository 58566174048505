import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { ROUTES } from '../modules/routing/config/constants'
import NotFoundRoute from './NotFound'
import AuthBasedRouteRestrictor from '../modules/routing/helpers/components/AuthBasedRouteRestrictor'
import Login from './Login'
import AppContainer from './AppContainer'
import HomeRoute from './Home'
import LoadingBackdrop from '../components/layout/LoadingBackdrop'

const AdminRoutes = React.lazy(() => import('./Admin'))

const AppRoutes = () => (
  <>
    <Routes>
      <Route
        path={ROUTES.RELATIVE_PATH}
        element={<AppContainer />}
      >
        <Route
          index
          element={<HomeRoute />}
        />

        {/* public routes */}
        <Route
          path={ROUTES.LOGIN.RELATIVE_PATH}
          element={<Login />}
        />

        {/* admin-exclusive routes */}
        <Route
          path={`${ROUTES.ADMIN.RELATIVE_PATH}/*`}
          element={(
            <AuthBasedRouteRestrictor
              lazyLoaded
              lazyLoadingFallback={<LoadingBackdrop />}
            >
              <AdminRoutes />
            </AuthBasedRouteRestrictor>
          )}
        />

        <Route path='*' element={<NotFoundRoute />} />
      </Route>
    </Routes>
  </>
)

export default AppRoutes
