import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import LanguageDataStoreSagaHandlers from '../../sagas/data/LanguageDataStore'

const sliceName = 'languagesStore'

const LanguageDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  LanguageDataStoreSagaHandlers
)

export default LanguageDataStoreSlice
