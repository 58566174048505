import * as React from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

const LoadingBackdrop = () => (
  <Backdrop
    sx={{ color: theme => theme.palette.primary.main, zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(225, 225, 225, 0.5)' }}
    open
  >
    <CircularProgress color='inherit' />
  </Backdrop>
)

export default LoadingBackdrop
