import GloballyExcludedClickerService from '../../../services/GloballyExcludedClicker'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const GloballyExcludedClickerDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'globallyExcludedClicker',
  GloballyExcludedClickerService,
  RESTModelNormalizationSchemas.globallyExcludedClicker.schema,
  [
    RESTModelNormalizationSchemas.globallyExcludedClicker.entityName
  ]
)

export default GloballyExcludedClickerDataStoreSagaHandlers
