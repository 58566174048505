import { useLayoutEffect, useState } from 'react'
import LinkManagementExperienceActions from '../actions/experiences/LinkManagement'
import { useDispatch } from 'react-redux'

/**
 * Provides access to the roles available inside the application.
 * @param linkId {string}: id of the link the clicks are returned for
 * @returns {[[StoreClick], function([StoreClick] | function([StoreClick]))]}: clicks of the link with id specified inside @linkId,
 * and changing the clicks from the outside.
 */
const useNormalizedLinkClicks = linkId => {
  const [linkClicks, setLinkClicks] = useState([])
  const dispatch = useDispatch()

  useLayoutEffect(
    () => {
      if (linkId) {
        dispatch(
          LinkManagementExperienceActions.fetchClicks(
            linkId,
            setLinkClicks
          )
        )
      }
    },
    [linkId, setLinkClicks, dispatch]
  )

  return [linkClicks, setLinkClicks]
}

export default useNormalizedLinkClicks
