import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import ApiKeyDataStoreSagaHandlers from '../../sagas/data/ApiKeyDataStore'

const sliceName = 'apiKeysStore'

const ApiKeyDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  ApiKeyDataStoreSagaHandlers
)

export default ApiKeyDataStoreSlice
