import { useMemo } from 'react'
import useNormalizedClicks from './useNormalizedClicks'

/**
 * Returns requested click(s) from state.data.clicks in a normalized fashion.
 * @param clickIds {string | string[]}: id of requested clicks.
 * @param asMap {boolean}: when multiple clicks are requested, return clicks as an object mapping clicks by their id.
 * @param deNormalizationConfig {{}}:
 * specify which related models are merged into the click object. Default config returns the same result as
 * useNormalizedClicks.
 * @param fetchAllEnabled {boolean}: enable fetching of all clicks, when no click id is passed.
 * @returns {StoreClick | StoreClick[]}
 */
const useClicks = (
  clickIds = null,
  asMap = false,
  deNormalizationConfig = {},
  fetchAllEnabled = false
) => {
  const normalizedClicks = useNormalizedClicks(clickIds, asMap, fetchAllEnabled)

  return useMemo(
    () => {
      const populateClick = click => click

      return normalizedClicks && (
        typeof clickIds === 'string' || typeof clickIds === 'number'
          ? populateClick(normalizedClicks)
          : asMap
            ? Object.keys(normalizedClicks)
              .reduce((clicks, clickId) => {
                clicks[clickId] = populateClick(normalizedClicks[clickId])
                return clicks
              }, {})
            : normalizedClicks.map(populateClick)
      )
    },
    [normalizedClicks, clickIds, asMap]
  )
}

export default useClicks
