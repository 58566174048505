import { v4 as uuidv4 } from 'uuid'

export const generateLogReference = () => uuidv4()

export const generateLogParameters = (reference, error) => {
  const logReference = reference || generateLogReference()

  return [
    `Ref ${logReference}`,
    Date.now(),
    error
  ]
}
