import { useDispatch, useSelector } from 'react-redux'
import { useLayoutEffect } from 'react'
import useDataStoreItemDetails from './useDataStoreItemDetails'

/**
 * Returns requested datastore item(s) from state.data[dataStore], but fetches details of all items, the first time the
 * hook is executed.
 * It is useful for REST models that have only a few items, that can be fetched all once and stored inside the store
 * during the lifetime of the application, without any major memory penalty. For ex. referential data.
 * @param itemIds {string | string[] | null}: id of requested items. `null` means, that all items are requested.
 * @param asMap {boolean}: when multiple items are requested, return items as an object mapping items by their id.
 * @param dataSelector {function(state: object): {}}: selector, that returns all data store item details(state.data[dataStore]).
 * @param dataFetcherActionCreator {function(itemIdsToFetch: string[]): {type: string, data: {}}}: creates an action,
 * that when dispatched, triggers the fetching of the requested data store items.
 * @param JSONStoreItemToStoreItem {function(jsonStoreItem: {}): {}}: function converting a json store-item to a
 * store-item object(class instance).
 * @returns {object | object[]}
 */
const useDataStoreItemsFetchedOnce = (
  itemIds, dataSelector, dataFetcherActionCreator, JSONStoreItemToStoreItem, asMap = false
) => {
  // validate arguments
  if (!dataSelector) {
    throw new Error('[useDataStoreItems] dataSelector is required')
  }
  if (!dataFetcherActionCreator) {
    throw new Error('[useDataStoreItems] dataFetcherActionCreator is required')
  }
  if (!JSONStoreItemToStoreItem) {
    throw new Error('[useDataStoreItems] JSONStoreItemToStoreItem is required')
  }

  const itemDetails = useSelector(dataSelector)
  const dispatch = useDispatch()

  useLayoutEffect(
    () => {
      if (Object.keys(itemDetails).length === 0) {
        // if dataStore is empty, all items are fetched
        dispatch(
          dataFetcherActionCreator(null) // null means, that all items are fetched
        )
      }
    },
    [itemDetails, dataFetcherActionCreator, dispatch]
  )

  return useDataStoreItemDetails(itemDetails, itemIds, asMap, JSONStoreItemToStoreItem, true)
}

export default useDataStoreItemsFetchedOnce
