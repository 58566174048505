import React from 'react'
import PropTypes from 'prop-types'
import LogService from '../../services/log'
import { generateLogParameters, generateLogReference } from '../../utilities'

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { errorReference: null }
  }

  static getDerivedStateFromError () {
    return { errorReference: generateLogReference() }
  }

  componentDidCatch (error, errorInfo) {
    LogService.error(
      ...generateLogParameters(
        this.state.errorReference,
        {
          ErrorBoundary: this.props.name,
          error,
          errorInfo
        }
      )
    )
  }

  render () {
    if (this.state.errorReference) {
      return (
        this.props.renderFallbackUI && this.props.renderFallbackUI(this.state.errorReference)
      ) || (
        <h1>Something went wrong. Error reference: {this.state.errorReference}</h1>
      )
    }

    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  /**
   * name {string}: unique identifier of the error boundary across the app
   */
  name: PropTypes.string.isRequired,
  /**
   * renderFallbackUI {func(string)}: renders fallback UI displayed when an error is caught.
   * The error's reference is sent as the first parameter.
   */
  renderFallbackUI: PropTypes.func
}

export default ErrorBoundary
