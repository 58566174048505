import ApiKeyService from '../../../services/ApiKey'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const ApiKeyDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'apiKey',
  ApiKeyService,
  RESTModelNormalizationSchemas.apiKey.schema,
  [
    RESTModelNormalizationSchemas.apiKey.entityName
  ]
)

export default ApiKeyDataStoreSagaHandlers
