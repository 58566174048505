import { ABAC_RULES, PERMISSIONS, USER_ROLES } from '../../modules/ac/constants'

/**
 * RBAC rules can be fetched from remote and be populated dynamically.
 * RBAC and ABAC rules are merged into a single property inside the store, called "rules".
 * When the population is done, the "rulesPopulated" flag is set to true to signal the app about the readiness of AC.
 */
const initialState = {
  rulesPopulated: true,
  rules: {
    // rbac rules fetched from remote
    [USER_ROLES.ADMIN]: {
      [PERMISSIONS.EXAMPLE_PERM]: true
    },
    // abac rules
    ...ABAC_RULES
  }
}

const AC = () => {
  return initialState
}

export default AC
