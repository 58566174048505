export const MAIN_HTTP_API_CONFIG = {
  URL: process.env.REACT_APP_MAIN_HTTP_API_URL
}

export const MAIN_HTTP_API_ENDPOINTS = {
  LOG_IN: '/login',
  USER: '/user/:id',
  USERS: '/user',
  LINK: '/link/:id',
  LINK_CLICKS: '/link/:id/clicks',
  LINKS: '/link',
  CLICK: '/click/:id',
  CLICKS: '/click',
  PROFILE: '/profile',
  API_KEY: '/api-key/:id',
  API_KEYS: '/api-key',
  GLOBALLY_EXCLUDED_CLICKER: '/globally-excluded-clicker/:id',
  GLOBALLY_EXCLUDED_CLICKERS: '/globally-excluded-clicker'
}
