import { all, put, takeLatest } from 'redux-saga/effects'
import DATA_STORES_TYPES from '../../types/data'
import OLError from '../../../modules/errorHandling/models/OLError'
import ErrorActions from '../../actions/Error'
import { NORMALIZATION_ENTITY_NAME_TO_DATA_STORE_SLICE_MAPPING } from '../../constants'

/**
 * Merge normalized entities into their associated data stores.
 * @param action {{ type: string, data: { entities: object } }}
 * @returns {Generator<CombinatorEffect<"ALL", SimpleEffect<"PUT", PutEffectDescriptor<*>>>|SimpleEffect<"PUT", PutEffectDescriptor<{type: string, data: {errorObject: OLError, shouldBeLogged: boolean, shouldBeDisplayed: boolean, shouldReferenceBeDisplayed: boolean, shouldBeAutoHidden: boolean}}>>, void, *>}
 */
function * handleMergeEntitiesIntoStores (action) {
  try {
    const { data: { entities } } = action
    const mergerActions = Object
      .keys(entities) // iterate over the received entity names
      .map(entityName => put(
        NORMALIZATION_ENTITY_NAME_TO_DATA_STORE_SLICE_MAPPING[entityName].actions.merge(entities[entityName]) // dispatch a merge action for each received entity
      )
      )
    // wait for all merge actions to finish
    yield all(
      mergerActions
    )
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError(
          '[DataStoresSaga.handleMergeEntitiesIntoStores]: Failed to merge entities into their own dataStore.',
          'Failed to merge entities into their own dataStore',
          e
        ),
        true,
        false
      )
    )
  }
}

function * DataStoresSaga () {
  yield takeLatest(DATA_STORES_TYPES.MERGE_ENTITIES_INTO_STORES, handleMergeEntitiesIntoStores)
}

export default DataStoresSaga
