import { useSelector } from 'react-redux'
import AuthExperienceSelectors from '../selectors/experiences/Auth'
import { useMemo } from 'react'
import StoreProfile from '../models/Profile'

/**
 * Logged in user's profile in a normalized form.
 * @returns {StoreProfile | null}
 */
const useNormalizedProfile = () => {
  const jsonProfile = useSelector(AuthExperienceSelectors.profile)

  return useMemo(
    () => jsonProfile && StoreProfile.FromJSON(jsonProfile),
    [jsonProfile]
  )
}

export default useNormalizedProfile
