import LAYOUT_TYPES from '../types/Layout'
import { makeActionCreator } from '../../modules/utilities/store'

const LayoutActions = {
  /**
   * @param appBarTitle {string}
   * @returns {{ type: string, data: { appBarTitle: string } }}
   */
  setAppBarTitle: makeActionCreator(LAYOUT_TYPES.SET_APPBAR_TITLE, 'appBarTitle'),
  /**
   * @param isLoading {boolean}
   * @param fetchId {string}
   * @returns {{ type: string, data: { isLoading: boolean, fetchId: string } }}
   */
  setIsLoading: makeActionCreator(LAYOUT_TYPES.SET_IS_LOADING, 'isLoading', 'fetchId'),
  /**
   * @param drawerMenuOpen {boolean}
   * @returns {{ type: string, data: { drawerMenuOpen: boolean } }}
   */
  setDrawerMenuOpen: makeActionCreator(LAYOUT_TYPES.SET_DRAWER_MENU_OPEN, 'drawerMenuOpen'),
  /**
   * @param chatDrawerOpen {boolean}
   * @returns {{ type: string, data: { chatDrawerOpen: boolean } }}
   */
  setChatDrawerOpen: makeActionCreator(LAYOUT_TYPES.SET_CHAT_DRAWER_OPEN, 'chatDrawerOpen'),
  /**
   * @param serviceDrawerOpen {boolean}
   * @returns {{ type: string, data: { serviceDrawerOpen: boolean } }}
   */
  setServiceDrawerOpen: makeActionCreator(LAYOUT_TYPES.SET_SERVICE_DRAWER_OPEN, 'serviceDrawerOpen'),
  /**
   * @param framelessModeTurnedOn {boolean}
   * @returns {{ type: string, data: { framelessModeTurnedOn: boolean } }}
   */
  setFramelessModeTurnedOn: makeActionCreator(LAYOUT_TYPES.SET_FRAMELESS_MODE_TURNED_ON, 'framelessModeTurnedOn')
}

export default LayoutActions
