const selectGloballyExcludedClickers = state => state.data.globallyExcludedClickers

const selectGloballyExcludedClickerById = globallyExcludedClickerId => state => state.data.globallyExcludedClickers[globallyExcludedClickerId]

const GloballyExcludedClickerDataStoreSelectors = {
  globallyExcludedClickers: selectGloballyExcludedClickers,
  globallyExcludedClickerById: selectGloballyExcludedClickerById
}

export default GloballyExcludedClickerDataStoreSelectors
