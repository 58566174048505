import mainHTTPClient from '../clients/mainHTTPClient'
import { MAIN_HTTP_API_ENDPOINTS } from '../constants/api/mainHttp'

/**
 * Delete specified click.
 * @param clickId {string}
 * @returns {Promise<AxiosResponse<string>>}: OK in case the deletion was successful.
 * @constructor
 */
const Delete = async clickId => mainHTTPClient.delete(
  MAIN_HTTP_API_ENDPOINTS.CLICK.replace(':id', clickId)
)

/**
 *
 * Search for clicks.
 * @param listMetaData {ListMetaData}
 * @returns {Promise<AxiosResponse<object[]>>}
 * @constructor
 */
const FindMany = async listMetaData => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.CLICKS,
  {
    ...listMetaData.toAxiosRequestParams()
  }
)

const ClickService = {
  Delete,
  FindMany
}

export default ClickService
