import useNormalizedLanguages from './useNormalizedLanguages'
import useRoles from './useRoles'
import { useMemo } from 'react'
import useNormalizedUsers from './useNormalizedUsers'

/**
 * Returns requested user(s) from state.data.users in a normalized fashion.
 * @param userIds {string | string[]}: id of requested users.
 * @param asMap {boolean}: when multiple users are requested, return users as an object mapping users by their id.
 * @param deNormalizationConfig {{family: boolean, language: boolean, role: boolean, photo: boolean, devices: boolean}}:
 * specify which related models are merged into the user object. Default config returns the same result as
 * useNormalizedUsers.
 * @param fetchAllEnabled {boolean}: enable fetching of all users, when no user id is passed.
 * @returns {StoreUser | StoreUser[]}
 */
const useUsers = (
  userIds = null,
  asMap = false,
  deNormalizationConfig = {
    language: false,
    role: false
  },
  fetchAllEnabled = false
) => {
  const normalizedUsers = useNormalizedUsers(userIds, asMap, fetchAllEnabled)
  const languages = useNormalizedLanguages(null, true)
  const roles = useRoles(null, true)

  return useMemo(
    () => {
      const populateUser = user => {
        if (deNormalizationConfig.role) {
          user.role = roles[user.roleId]
        }
        if (deNormalizationConfig.language) {
          user.language = languages[user.languageId]
        }

        return user
      }

      return normalizedUsers && (
        typeof userIds === 'string' || typeof userIds === 'number'
          ? populateUser(normalizedUsers)
          : asMap
            ? Object.keys(normalizedUsers)
              .reduce((users, userId) => {
                users[userId] = populateUser(normalizedUsers[userId])
                return users
              }, {})
            : normalizedUsers.map(populateUser)
      )
    },
    [normalizedUsers, userIds, languages, roles, asMap, deNormalizationConfig]
  )
}

export default useUsers
