import React, { useCallback, forwardRef } from 'react'
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'

import TextField from '@mui/material/TextField'

// Utility wrapper around @mui/material/TextField
const HookedTextField = forwardRef(
  ({ name, ...textFieldProps }, ref) => {
    const renderInput = useCallback(
      ({ field, fieldState: { error } }) => (
        <TextField
          error={!!error}
          margin='normal'
          variant='standard'
          {...textFieldProps}
          helperText={error?.message}
          {...field}
          ref={ref}
        />
      ),
      [textFieldProps, ref]
    )

    return (
      <Controller
        name={name}
        render={renderInput}
      />
    )
  }
)

HookedTextField.propTypes = {
  /** Field name **/
  name: PropTypes.string.isRequired
  /** @param textFieldProps {restProp}: Props for customizing the underlying @mui/material/TextField.
   * The 'value', 'onChange', 'onBlur', 'ref', 'label', 'helperText' props can't be overridden. **/
}

export default HookedTextField
