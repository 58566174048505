import mainHTTPClient from '../clients/mainHTTPClient'
import { MAIN_HTTP_API_ENDPOINTS } from '../constants/api/mainHttp'

/**
 * Create a new globallyExcludedClicker.
 * @param globallyExcludedClickerData {object}: GloballyExcludedClicker data.
 * @returns {Promise<AxiosResponse<object>>}: newly created globallyExcludedClicker.
 * @constructor
 */
const Create = async globallyExcludedClickerData => mainHTTPClient.post(
  MAIN_HTTP_API_ENDPOINTS.GLOBALLY_EXCLUDED_CLICKERS,
  globallyExcludedClickerData
)

/**
 * Delete specified globallyExcludedClicker.
 * @param globallyExcludedClickerId {string}
 * @returns {Promise<AxiosResponse<string>>}: OK in case the deletion was successful.
 * @constructor
 */
const Delete = async globallyExcludedClickerId => mainHTTPClient.delete(
  MAIN_HTTP_API_ENDPOINTS.GLOBALLY_EXCLUDED_CLICKER.replace(':id', globallyExcludedClickerId)
)

/**
 *
 * Search for globallyExcludedClickers.
 * @param listMetaData {ListMetaData}
 * @returns {Promise<AxiosResponse<object[]>>}
 * @constructor
 */
const FindMany = async listMetaData => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.GLOBALLY_EXCLUDED_CLICKERS,
  {
    ...listMetaData.toAxiosRequestParams()
  }
)

const GloballyExcludedClickerService = {
  Create,
  Delete,
  FindMany
}

export default GloballyExcludedClickerService
