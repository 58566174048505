import { createReducer } from '../../modules/utilities/store'
import MESSAGES_TYPES from '../types/Messages'
import { addSeconds } from '../../modules/utilities/date'
import { v4 as uuidV4 } from 'uuid'

const initialState = {
  items: []
}

const handlers = {
  [MESSAGES_TYPES.ADD]: (state, action) => {
    const { type, text, localizationParams, hideAfterSec } = action.data
    const messageItem = {
      id: uuidV4(),
      type,
      text,
      localizationParams,
      hideAfterSec,
      expirationDate: hideAfterSec ? addSeconds(new Date(), hideAfterSec) : undefined
    }

    return ({
      ...state,
      items: [
        ...state.items,
        messageItem
      ]
    })
  },
  [MESSAGES_TYPES.HIDE]: (state, action) => ({
    ...state,
    items: state.items.filter(message => message.id !== action.data.messageId)
  }),
  [MESSAGES_TYPES.GC]: state => {
    const now = new Date()

    return ({
      ...state,
      // messages that have an expiration date smalled than the current date are considered expired and they are garbage collected
      // messages with no expiration date are considered persistent, and are not garbage collected
      items: state.items.filter(message => !message.expirationDate || now < message.expirationDate)
    })
  }
}

const MessagesStore = createReducer(initialState, handlers)

export default MessagesStore
