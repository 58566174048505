/**
 * Builds the absolute path for the given route, by iterating over all of its ancestor routes.
 * @param route {object}
 * @returns {string}: absolute path.
 */
const getAbsolutePathStringOfRoute = route => `${route._parent
  ? getAbsolutePathStringOfRoute(route._parent)
  : ''}${route._path === '/' ? '' : `/${route._path}`}`

/**
 * Creates a helper function, that replaces the dynamic path parameters inside a dynamic path.
 * @param absolutePathString {string}: path that will be replaced.
 * @returns {function(string): void}: function, that replaces dynamic portions of the path with the parameters received
 *  by the function
 */
const createAbsolutePathParamReplacerFunctionForAbsolutePathString = absolutePathString => (...params) => {
  let nextParamIndex = 0
  return absolutePathString.replace(
    /:[a-z]+/i,
    () => params[nextParamIndex++]
  )
}

/**
 * Mark up routes with properties, that help with the accessibility of routes:
 * - obtaining a route's relative path
 * - obtaining a route's absolute path
 * @param ROUTE_DEFINITIONS {{}}: route definitions.
 * @param _parent {{}|undefined}: parent route.
 * @returns {{}}
 */
export const markupRoutesWithPathHelperProperties = (ROUTE_DEFINITIONS, _parent) => {
  const currentRouteConfig = ({
    _parent,
    _path: ROUTE_DEFINITIONS._path,
    /**
     * Returns the path of the route relative to its parent route.
     * @returns {string}
     */
    get RELATIVE_PATH () {
      return this._path
    },
    /**
     * Calculates & returns the absolute path of the route or a function, that replaces dynamic parameters inside the
     * absolute path of the route in case of dynamic routes.
     * @returns {(function(): string)|string}
     */
    get ABSOLUTE_PATH () {
      const absolutePathString = getAbsolutePathStringOfRoute(this) || '/' // in case of root paths, an empty string is returned by getAbsolutePathStringOfRoute, so we manually force '/'

      return absolutePathString.includes(':')
        /**
         * if it is a dynamic route, return a function that replaces the dynamic route parameters inside
         * `absolutePathString` with the parameters received by the function(in the received order).
         */
        ? createAbsolutePathParamReplacerFunctionForAbsolutePathString(absolutePathString)
        /**
         * if it is a static route, just return the string value of the route
         */
        : absolutePathString
    }
  })

  // add each sub-route as a new attribute under the current route
  Object
    .keys(ROUTE_DEFINITIONS)
    .forEach(
      routeAttributeName => {
        // reserved attributes can't be overridden
        if (!['_path', '_parent', 'RELATIVE_PATH', 'ABSOLUTE_PATH'].includes(routeAttributeName)) {
          currentRouteConfig[routeAttributeName] = markupRoutesWithPathHelperProperties(
            ROUTE_DEFINITIONS[routeAttributeName],
            currentRouteConfig
          )
        }
      }
    )

  return currentRouteConfig
}

/**
 * Encodes provided `searchParameters` into the url.
 * @param url {string}: Url to encode the parameters into.
 * @param searchParameters {object}: Parameters that will be encoded into the url. The name of the encoded parameter is
 * the key- and the encoded value is the value- of the attribute inside the object.
 */
export const encodeSearchParametersIntoUrl = (url, searchParameters) =>
  `${url}?${
    URLSearchParams
      ? (new URLSearchParams(searchParameters)).toString()
      : Object.keys(searchParameters).map(key => `${key}=${searchParameters[key]}`).join('&')
  }`
