import { useDispatch, useSelector } from 'react-redux'
import LayoutSelectors from '../selectors/Layout'
import { useLayoutEffect } from 'react'
import LayoutActions from '../actions/Layout'

/**
 * Get/Set state.ui.layout.appBarTitle
 * @param [newTitle] {string}: if provided, state.ui.layout.appBarTitle is replaced with this title.
 * @returns {string} state.ui.layout.appBarTitle
 */
const useAppBarTitle = newTitle => {
  const appBarTitle = useSelector(LayoutSelectors.appBarTitle)
  const dispatch = useDispatch()

  useLayoutEffect(
    () => {
      if (typeof newTitle === 'string') {
        dispatch(
          LayoutActions.setAppBarTitle(newTitle)
        )
      }
    },
    [dispatch, newTitle]
  )

  return appBarTitle
}

export default useAppBarTitle
