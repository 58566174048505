import * as React from 'react'
import { useLayoutEffect, useState } from 'react'
import initializeClients from '../../../clients'
import LoadingBackdrop from '../../layout/LoadingBackdrop'
import initIntl from '../../../modules/i18n'

// Prepares the application: initializes dependencies
const ApplicationLoader = React.memo(
  ({ children }) => {
    const [intlReady, setIntlReady] = useState(false)
    const [clientsFinishedLoading, setClientsFinishedLoading] = useState(false)

    useLayoutEffect(() => {
      (async () => {
        await initIntl()
        setIntlReady(true)
      })()
    }, [setIntlReady])

    useLayoutEffect(
      () => {
        (async () => {
          await initializeClients()
          setClientsFinishedLoading(true)
        })()
      },
      [setClientsFinishedLoading]
    )

    const applicationDependenciesFinishedLoading = intlReady && clientsFinishedLoading

    return applicationDependenciesFinishedLoading
      ? children
      : <LoadingBackdrop />
  }
)

export default ApplicationLoader
