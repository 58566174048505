import ApiKeyDataStoreSelectors from '../selectors/data/ApiKeys'
import ApiKeyDataStoreSlice from '../slices/data/ApiKeyDataStoreSlice'
import StoreApiKey from '../models/ApiKey'
import useDataStoreItems from './helpers/useDataStoreItems'

const JSONStoreApiKeyToStoreApiKey = JSONStoreApiKey => StoreApiKey.FromJSON(JSONStoreApiKey)

/**
 * Returns requested-/all apiKey(s) from state.data.apiKeys.
 * @param apiKeyIds {string | string[]}: id of requested apiKeys.
 * @param asMap {boolean}: when multiple apiKeys are requested, return apiKeys as an object mapping apiKeys by their id.
 * @param fetchAllEnabled {boolean}: enable fetching of all apiKeys, when no apiKey id is passed.
 * @returns {StoreApiKey | StoreApiKey[]}
 */
const useNormalizedApiKeys = (
  apiKeyIds = null,
  asMap = false,
  fetchAllEnabled = false
) => useDataStoreItems(
  apiKeyIds,
  ApiKeyDataStoreSelectors.apiKeys,
  ApiKeyDataStoreSlice.actions.fetch,
  JSONStoreApiKeyToStoreApiKey,
  asMap,
  fetchAllEnabled
)

export default useNormalizedApiKeys
