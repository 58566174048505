import { v4 as uuidV4 } from 'uuid'

/**
 * @param message {string}: message containing a precise description of the error.
 * @param displayedMessage {string}: message displayable to the user.
 * @param details {{}}: more details, that help with understanding the error.
 */
class OLError extends Error {
  constructor (message, displayedMessage, details) {
    super(message)

    this.reference = uuidV4()
    this.createdAt = new Date()
    this.displayedMessage = displayedMessage
    this.details = details
  }

  getLogInfo () {
    const logInfo = {
      reference: this.reference,
      createdAt: this.createdAt,
      errorMessage: this.message
    }

    if (this.details) {
      logInfo.details = this.details
    }

    return logInfo
  }

  /**
   * Returns message as parameters that are accepted by i18next, so message can be localized.
   * @param withReference {boolean}: include reference into the error message?
   * @returns {[string, *]}
   */
  getLocalizedMessageParameters (withReference = true) {
    const referenceIdentifier = 'errorReference'
    const localizedMessageparameters = [
      `${this.displayedMessage || 'Error'}${withReference ? `.\n Error reference: {{${referenceIdentifier}}}` : ''}`
    ]
    withReference && localizedMessageparameters.push({ [referenceIdentifier]: this.reference })

    return localizedMessageparameters
  }

  getDisplayedMessageString (withReference = true) {
    return `${this.displayedMessage || 'Error'}${withReference ? `\n Error reference: {${this.reference}}` : ''}`
  }
}

export default OLError
