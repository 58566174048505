export const USER_ROLES = {
  ADMIN: 'ADMIN',
  USER: 'USER',
  API_USER: 'API_USER'
}

export const LANGUAGES = {
  ENGLISH: 'EN'
}

export const PERMISSIONS = {
  EXAMPLE_PERM: 'EXAMPLE_PERM'
}

/**
 * Attribute-based access control.
 * There are advanced components use cases when associating a permission with a role is not enough and certain attributes of
 * the protected domain and the user have to be checked to verify if access criteria are met.
 * This type of components can't be populated from a remote source.
 */
export const ABAC_RULES = {
  [USER_ROLES.ADMIN]: {
    // user can only edit the post if it is the owner of it
    [PERMISSIONS.EXAMPLE_PERM]: (post, user) => post?.owner === user.id
  }
}
