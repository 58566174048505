import { makeActionCreator } from '../../modules/utilities/store'
import ERROR_TYPES from '../types/Error'

/**
 * @param errorObject {OLError}
 * @param {boolean} [shouldBeLogged=true] - should the error be logged by the configured log service?
 * @param {boolean} [shouldBeDisplayed=true] - should the error be displayed to the user?
 * @param {boolean} [shouldReferenceBeDisplayed=true] - should the error-reference be displayed together with the error?
 * @param {boolean} [shouldBeAutoHidden=false] - should the displayed error message be persistent or hidden automatically after a certain amount of time?
 * @type {function(errorObject: OLError, shouldBeLogged: boolean, shouldBeDisplayed: boolean, shouldReferenceBeDisplayed: boolean, shouldBeAutoHidden: boolean): {type: string, data: {errorObject: OLError, shouldBeLogged: boolean, shouldBeDisplayed: boolean, shouldReferenceBeDisplayed: boolean, shouldBeAutoHidden: boolean}}}
 */
const triggerError = makeActionCreator(ERROR_TYPES.TRIGGER, 'errorObject', 'shouldBeLogged', 'shouldBeDisplayed', 'shouldReferenceBeDisplayed', 'shouldBeAutoHidden')

const ErrorActions = {
  trigger: triggerError
}

export default ErrorActions
