import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
// import reportWebVitals from './reportWebVitals'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

/** TODO: upgrade to react v18 initialization api when "mui" will support concurrent mode.
 * Observed errors when the app is initialized with the v18 api:
 * - 1: when switching to the profile screen from the dashboard, an mui internal error occurs in the Transition
 * component.
 * - 2: the ExtendedDataGrid filter inputs are incorrectly initialized on all pages containing ExtendedDataGrid
 * **/
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log) // TODO: report web vitals on a reporting service
