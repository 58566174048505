import useDataStoreItemsFetchedOnce from './helpers/useDataStoreItemsFetchedOnce'
import LanguageDataStoreSelectors from '../selectors/data/Languages'
import LanguageDataStoreSlice from '../slices/data/LanguageDataStoreSlice'
import StoreLanguage from '../models/Language'

const JSONStoreLanguageToStoreLanguage = JSONStoreLanguage => StoreLanguage.FromJSON(JSONStoreLanguage)

/**
 * Returns requested-/all language(s) from state.data.languages.
 * @param languageIds {string | string[] | null}: id of requested languages. If null, all languages are requested.
 * @param asMap {boolean}: when multiple languages are requested, return languages as an object mapping languages by their id.
 * @returns {StoreLanguage | StoreLanguage[]}
 */
const useNormalizedLanguages = (languageIds = null, asMap = false) => useDataStoreItemsFetchedOnce(
  languageIds,
  LanguageDataStoreSelectors.languages,
  LanguageDataStoreSlice.actions.fetch,
  JSONStoreLanguageToStoreLanguage,
  asMap
)

export default useNormalizedLanguages
