import React, { useCallback, useLayoutEffect, useMemo } from 'react'
import LoginUI from './Login.UI'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import useYupInputSchemaBuilder from '../../modules/formManagement/hooks/useYupInputSchemaBuilder'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import storeHooks from '../../redux/hooks'
import { useDispatch } from 'react-redux'
import AuthExperienceActions from '../../redux/actions/experiences/Auth'
import { Navigate, useLocation } from 'react-router-dom'
import { ROUTES } from '../../modules/routing/config/constants'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { LOGO_URL } from '../../constants/general'
import Messages from '../../components/layout/Messages'

const AuthPageContainer = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    width: '100%',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.customColors.urlShortenerMain
  })
)

const Login = () => {
  const { t } = useTranslation()
  const inputSchemaBuilder = useYupInputSchemaBuilder()
  const isLoading = storeHooks.useLoading()
  const dispatch = useDispatch()
  const location = useLocation()
  const { isAuthenticated, authCodePrefix, authCodePrefixFetched } = storeHooks.useAuthState()

  const onLoginRedirectPath = location.state?.from || { pathname: ROUTES.ADMIN.ABSOLUTE_PATH }

  useLayoutEffect(() => {
    // fetch the auth code prefix the first time the login screen is displayed
    dispatch(
      AuthExperienceActions.fetchAuthCodePrefix()
    )
  }, [dispatch])

  const loginFormLabels = useMemo(
    () => ({
      title: t('Login.Title', 'Sign in'),
      emailInput: t('Login.EmailInput', 'Email Address'),
      passwordInput: t('Login.PasswordInput', 'Password'),
      rememberMe: t('Login.RememberMe', 'Remember me'),
      authCodeInput: t('Login.AuthCodeInput', 'Auth code'),
      loginButton: t('Login.LoginButton', 'Sign In'),
      forgotPasswordLinkLabel: t('Login.ForgotPasswordLinkLabel', 'Forgot password?')
    }),
    [t]
  )

  const twoFASecondStep = !!authCodePrefix

  const formSchema = useMemo(
    () => yup.object({
      email: inputSchemaBuilder.Email(!twoFASecondStep),
      password: inputSchemaBuilder.Text(!twoFASecondStep),
      rememberMe: inputSchemaBuilder.Boolean(false),
      authCode: inputSchemaBuilder.Text(twoFASecondStep)
    }),
    [inputSchemaBuilder, twoFASecondStep]
  )

  const form = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      email: '',
      password: '',
      rememberMe: false,
      authCode: ''
    }
  })

  const { handleSubmit: formHandleSubmit } = form

  const handleSubmit = useCallback(
    (...args) => formHandleSubmit(
      data => !twoFASecondStep
        ? dispatch(
          AuthExperienceActions.logIn(
            data.email,
            data.password
          )
        )
        : dispatch(
          AuthExperienceActions.validateAuthCode(
            data.authCode,
            data.rememberMe
          )
        )
    )(...args),
    [formHandleSubmit, twoFASecondStep, dispatch]
  )

  if (isAuthenticated) {
    return (
      <Navigate
        to={onLoginRedirectPath}
        replace
      />
    )
  }

  return (
    <FormProvider {...form}>
      <AuthPageContainer>
        <LoginUI
          labels={loginFormLabels}
          onSubmit={handleSubmit}
          forgotPasswordPageLink='#'
          twoFAAuthCodePrefix={authCodePrefix}
          logoURI={LOGO_URL}
          displayMode={(
            twoFASecondStep
              ? LoginUI.DISPLAY_MODES.TWO_FA_SECOND_STEP
              : LoginUI.DISPLAY_MODES.TWO_FA_FIRST_STEP
          )}
          disabled={isLoading || !authCodePrefixFetched}
        />
        <Messages />
      </AuthPageContainer>
    </FormProvider>
  )
}

export default Login
