import { put, takeLatest } from 'redux-saga/effects'
import ClickService from '../../../services/Click'
import ErrorActions from '../../actions/Error'
import OLError from '../../../modules/errorHandling/models/OLError'
import CLICK_MANAGEMENT_EXPERIENCE_TYPES from '../../types/experiences/ClickManagement'
import LayoutActions from '../../actions/Layout'
import MessageActions from '../../actions/Messages'
import { AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT, DISPLAYED_MESSAGE_TYPES } from '../../../constants/general'
import ClickDataStoreSlice from '../../slices/data/ClickDataStoreSlice'

function * deleteClick (action) {
  const fetchId = 'ClickManagementExperienceSaga.deleteClick'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { clickId, onSuccess } } = action

    const result = yield ClickService.Delete(clickId)
    if (result.status === 200) {
      // execute success callback
      onSuccess && onSuccess(clickId)
      // delete item from the store
      yield put(
        ClickDataStoreSlice.actions.deleteItem(clickId)
      )
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['DELETE_CLICK_SUCCESS', 'Click deleted'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[ClickManagementExperience->deleteClick]: Failed to delete click', 'Failed to delete click', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * ClickManagementExperienceSaga () {
  yield takeLatest(CLICK_MANAGEMENT_EXPERIENCE_TYPES.DELETE, deleteClick)
}

export default ClickManagementExperienceSaga
