import LinkDataStoreSelectors from '../selectors/data/Links'
import LinkDataStoreSlice from '../slices/data/LinkDataStoreSlice'
import StoreLink from '../models/Link'
import useDataStoreItems from './helpers/useDataStoreItems'

const JSONStoreLinkToStoreLink = JSONStoreLink => StoreLink.FromJSON(JSONStoreLink)

/**
 * Returns requested-/all link(s) from state.data.links.
 * @param linkIds {string | string[]}: id of requested links.
 * @param asMap {boolean}: when multiple links are requested, return links as an object mapping links by their id.
 * @param fetchAllEnabled {boolean}: enable fetching of all links, when no link id is passed.
 * @returns {StoreLink | StoreLink[]}
 */
const useNormalizedLinks = (
  linkIds = null,
  asMap = false,
  fetchAllEnabled = false
) => useDataStoreItems(
  linkIds,
  LinkDataStoreSelectors.links,
  LinkDataStoreSlice.actions.fetch,
  JSONStoreLinkToStoreLink,
  asMap,
  fetchAllEnabled
)

export default useNormalizedLinks
