import { useMemo } from 'react'

const reduceItemArrayToItemMap = (itemMap, item) => {
  itemMap[item.id] = item
  return itemMap
}

/**
 * Returns details of requested datastore item(s) from the provided itemDetails collection.
 * @param itemDetails {{}}: item detail collection. it must contain at least the details of the requested items.
 * @param itemIds {string | string[] | null}: id of requested items. `null` means, that all items are requested.
 * @param asMap {boolean}: when multiple items are requested, return items as an object mapping items by their id.
 * that when dispatched, triggers the fetching of the requested data store items.
 * @param JSONStoreItemToStoreItem {function(jsonStoreItem: {}): {}}: function converting a json store-item to a
 * store-item object(class instance).
 * @param returnAllEnabled {boolean}: return all items, when no itemId is passed.
 * @returns {object | object[]}
 */
const useDataStoreItemDetails = (
  itemDetails, itemIds, asMap = false, JSONStoreItemToStoreItem, returnAllEnabled = false
) => useMemo(
  () => {
    if (itemIds === null && returnAllEnabled) {
      // all items requested: return all items
      const itemArray = Object.values(itemDetails).map(JSONStoreItemToStoreItem)
      return asMap
        ? itemArray.reduce(reduceItemArrayToItemMap, {})
        : itemArray
    } else if (typeof itemIds === 'string' || typeof itemIds === 'number') {
      // single item requested: return the requested items' data
      return itemDetails[itemIds] && JSONStoreItemToStoreItem(itemDetails[itemIds])
    } else if (Array.isArray(itemIds)) {
      // multiple items requested: return data of the requested items
      const itemArray = itemIds.reduce(
        (items, itemId) => {
          if (itemDetails[itemId]) {
            items.push(JSONStoreItemToStoreItem(itemDetails[itemId]))
          }
          return items
        },
        []
      )
      return asMap
        ? itemArray.reduce(reduceItemArrayToItemMap, {})
        : itemArray
    }

    return null
  },
  [itemIds, itemDetails, JSONStoreItemToStoreItem, asMap, returnAllEnabled]
)

export default useDataStoreItemDetails
