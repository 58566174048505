const AUTH_EXPERIENCE_TYPES = {
  LOG_IN: '[authExperience] logIn',
  LOG_OUT: '[authExperience] logOut',
  AUTH0_LOGIN: '[authExperience] auth0Login',
  AUTH0_LOGOUT: '[authExperience] auth0Logout',
  VALIDATE_AUTH0_LOGIN: '[authExperience] validateAuth0Login',
  VERIFY_AUTHENTICATION: '[authExperience] verifyAuthentication',
  VALIDATE_AUTH_TOKEN: '[authExperience] validateAuthToken',
  SET_IS_AUTHENTICATED: '[authExperience] setIsAuthenticated',
  SET_AUTH_CHECKED: '[authExperience] setAuthChecked',
  PERSIST_AUTH_TOKEN: '[authExperience] persistAuthToken',
  REMOVE_AUTH_TOKEN: '[authExperience] removeAuthToken',
  SET_AUTH_CODE_PREFIX: '[authExperience] setAuthCodePrefix',
  FETCH_AUTH_CODE_PREFIX: '[authExperience] fetchAuthCodePrefix',
  PERSIST_AUTH_CODE_PREFIX: '[authExperience] persistAuthCodePrefix',
  CLEAR_AUTH_CODE_PREFIX: '[authExperience] clearAuthCodePrefix',
  SET_AUTH_CODE_PREFIX_FETCHED: '[authExperience] setAuthCodePrefixFetched',
  VALIDATE_AUTH_CODE: '[authExperience] validateAuthCode',
  SET_PROFIlE: '[authExperience] setProfile',
  EDIT_PROFIlE: '[authExperience] editProfile'
}

export default AUTH_EXPERIENCE_TYPES
