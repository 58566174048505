import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Checkbox from '@mui/material/Checkbox'

const helperSX = { mx: 0 }

const HookedCheckbox = ({ name, label, required, defaultValue, disabled, labelPosition, containerProps }) => {
  const renderInput = useCallback(
    ({ field, fieldState: { error } }) => (
      <FormControl
        {...containerProps}
        error={!!error}
        margin='normal'
        required={required}
      >
        <FormControlLabel
          control={(
            <Checkbox
              {...field}
              checked={field.value}
              onChange={e => field.onChange(e.target.checked)}
              color='primary'
              disabled={disabled}
            />
          )}
          label={label}
          labelPlacement={labelPosition}
        />
        <FormHelperText sx={helperSX}>
          {error?.message}
        </FormHelperText>
      </FormControl>
    ),
    [label, required, disabled, labelPosition, containerProps]
  )

  return (
    <Controller
      name={name}
      render={renderInput}
      defaultValue={defaultValue}
    />
  )
}

HookedCheckbox.propTypes = {
  /** Field name **/
  name: PropTypes.string.isRequired,
  /** Label **/
  label: PropTypes.node,
  /** Is field required? **/
  required: PropTypes.bool,
  /** Default value of field **/
  defaultValue: PropTypes.any,
  /** Is field disabled? **/
  disabled: PropTypes.bool,
  /** Where the label is placed relative to the switch: See [labelPlacement](https://mui.com/material-ui/api/form-control-label/) **/
  labelPosition: PropTypes.string
}

export default HookedCheckbox
