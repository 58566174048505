import { commonHandlers, createReducer } from '../../modules/utilities/store'
import themes from '../../modules/theming/themes'
import THEME_TYPES from '../types/Theme'
import GLOBAL_TYPES from '../types/Global'

const themeNames = Object.keys(themes)

const initialState = {
  availableThemeNames: themeNames,
  selectedThemeName: themeNames[0]
}

const handlers = {
  [THEME_TYPES.SELECT]: commonHandlers.setValue('selectedThemeName'),
  [GLOBAL_TYPES.RESET_STORE]: commonHandlers.resetState(initialState)
}

const ThemeStore = createReducer(
  initialState,
  handlers
)

export default ThemeStore
