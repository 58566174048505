import { put, takeLatest } from 'redux-saga/effects'
import GloballyExcludedClickerService from '../../../services/GloballyExcludedClicker'
import ErrorActions from '../../actions/Error'
import OLError from '../../../modules/errorHandling/models/OLError'
import GLOBALLY_EXCLUDED_CLICKER_MANAGEMENT_EXPERIENCE_TYPES
  from '../../types/experiences/GloballyExcludedClickerManagement'
import LayoutActions from '../../actions/Layout'
import MessageActions from '../../actions/Messages'
import { AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT, DISPLAYED_MESSAGE_TYPES } from '../../../constants/general'
import GloballyExcludedClickerDataStoreSlice from '../../slices/data/GloballyExcludedClickerDataStoreSlice'

function * createGloballyExcludedClicker (action) {
  const fetchId = 'GloballyExcludedClickerManagementExperienceSaga.createGloballyExcludedClicker'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { globallyExcludedClickerItem, onSuccess } } = action

    const result = yield GloballyExcludedClickerService.Create(globallyExcludedClickerItem.toNormalizedRESTModel())
    if (result.status === 200) {
      // add newly created item to the store
      yield put(
        GloballyExcludedClickerDataStoreSlice.actions.addItem(
          result.data.id,
          result.data
        )
      )
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['CREATE_GLOBALLY_EXCLUDED_CLICKER_SUCCESS', 'GloballyExcludedClicker successfully created'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
      onSuccess && onSuccess()
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[GloballyExcludedClickerManagementExperience->createGloballyExcludedClicker]: Failed to create globallyExcludedClicker', 'Failed to create globallyExcludedClicker', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * deleteGloballyExcludedClicker (action) {
  const fetchId = 'GloballyExcludedClickerManagementExperienceSaga.deleteGloballyExcludedClicker'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { globallyExcludedClickerId } } = action

    const result = yield GloballyExcludedClickerService.Delete(globallyExcludedClickerId)
    if (result.status === 200) {
      // delete item from the store
      yield put(
        GloballyExcludedClickerDataStoreSlice.actions.deleteItem(globallyExcludedClickerId)
      )
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['DELETE_GLOBALLY_EXCLUDED_CLICKER_SUCCESS', 'GloballyExcludedClicker deleted'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[GloballyExcludedClickerManagementExperience->deleteGloballyExcludedClicker]: Failed to delete globallyExcludedClicker', 'Failed to delete globallyExcludedClicker', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * GloballyExcludedClickerManagementExperienceSaga () {
  yield takeLatest(GLOBALLY_EXCLUDED_CLICKER_MANAGEMENT_EXPERIENCE_TYPES.CREATE, createGloballyExcludedClicker)
  yield takeLatest(GLOBALLY_EXCLUDED_CLICKER_MANAGEMENT_EXPERIENCE_TYPES.DELETE, deleteGloballyExcludedClicker)
}

export default GloballyExcludedClickerManagementExperienceSaga
