import { takeLatest } from 'redux-saga/effects'

/**
 * Creates connectable sagas for datastore management.
 * @param slice {object}: the slice the saga handlers are created for.
 * @param dataStoreTypes {{FETCH: string, FETCH_RESULT: string, MERGE: string, RESET: string}}
 * @param sagaHandlers {function(slice: object): { fetch: function({type: string, data: {userIds: string[] | undefined}}): Generator, fetchResult: function({type: string, data: {result: {}, callback: function(storeValue: object)}}): Generator, fetchId: string }}
 * @returns {{ saga: ((function(): Generator<SimpleEffect<"FORK", ForkEffectDescriptor<never>>, void, *>)|*), fetchIds: { fetchId: string } }}
 */
const createDataStoreSagas = (slice, dataStoreTypes, sagaHandlers) => {
  if (sagaHandlers) {
    const sliceInjectedSagaHandlers = sagaHandlers(slice)

    return ({
      saga: function * () {
        if (sliceInjectedSagaHandlers.fetch) {
          yield takeLatest(dataStoreTypes.FETCH, sliceInjectedSagaHandlers.fetch)
        }
        if (sliceInjectedSagaHandlers.fetchResult) {
          yield takeLatest(dataStoreTypes.FETCH_RESULT, sliceInjectedSagaHandlers.fetchResult)
        }
      },
      fetchIds: {
        fetchId: sliceInjectedSagaHandlers.fetchId
      }
    })
  }
}

export default createDataStoreSagas
