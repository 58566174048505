import ClickService from '../../../services/Click'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const ClickDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'click',
  ClickService,
  RESTModelNormalizationSchemas.click.schema,
  [
    RESTModelNormalizationSchemas.click.entityName
  ]
)

export default ClickDataStoreSagaHandlers
