import { makeActionCreator } from '../../modules/utilities/store'
import MESSAGES_TYPES from '../types/Messages'

/**
 * Creates an action, that when dispatched, adds a new message to display to the `messages` store.
 * @param type {DISPLAYED_MESSAGE_TYPES}: message type.
 * @param text {string}: displayed text.
 * @param localizationParams {[]}: if you want to display a localized message, provide an array of parameters, that will
 * be passed as parameters for the localization function(t) inside the message component.
 * @param hideAfterSec {int}: message is hidden after @hideAfterSec seconds.
 * @type {function(type: DISPLAYED_MESSAGE_TYPES, text: string, localizationParams: [], hideAfterSec: int): {type:string, data: {type: DISPLAYED_MESSAGE_TYPES, text: string, localizationParams: [], hideAfterSec: int}}}:
 */
const addMessage = makeActionCreator(MESSAGES_TYPES.ADD, 'type', 'text', 'localizationParams', 'hideAfterSec')

/**
 * @param messageId {string}: message to hide.
 * @type {function(messageId: string): {type:string, data: {messageId: string}}}
 */
const hideMessage = makeActionCreator(MESSAGES_TYPES.HIDE, 'messageId')

/**
 * Creates an action, that when dispatched, removes hidden(expired) messages.
 * @type {function(): {type: 'string', data: undefined}}
 */
const garbageCollection = makeActionCreator(MESSAGES_TYPES.GC)

const MessageActions = {
  add: addMessage,
  hide: hideMessage,
  garbageCollection
}

export default MessageActions
