import mainHTTPClient from '../clients/mainHTTPClient'
import { MAIN_HTTP_API_ENDPOINTS } from '../constants/api/mainHttp'

const AUTH_TOKEN_SETTING_STORAGE_KEY = 'at'

/**
 * Email-based authentication.
 * @param username {string}
 * @param password {string}
 * @returns {Promise<AxiosResponse<any>>}: auth code prefix.
 * @constructor
 */
const LogIn = (username, password) => mainHTTPClient.post(
  MAIN_HTTP_API_ENDPOINTS.LOG_IN,
  { username, password }
)

/**
 * Persists the logged-in user's authentication token.
 * @param authToken {string}
 * @param longTermStorage {boolean}: whether to save the token temporarily or in the long-term.
 * @returns {Promise<AxiosResponse<any>>}: auth token.
 * @constructor
 */
const PersistAuthToken = (authToken, longTermStorage) => (
  longTermStorage ? window.localStorage : window.sessionStorage
).setItem(
  AUTH_TOKEN_SETTING_STORAGE_KEY,
  authToken
)

/**
 * Get persisted authentication token.
 * @returns {string | null}: auth token.
 * @constructor
 */
const GetAuthToken = () => {
  const sessionToken = window.sessionStorage.getItem(AUTH_TOKEN_SETTING_STORAGE_KEY)
  const localStorageToken = window.localStorage.getItem(AUTH_TOKEN_SETTING_STORAGE_KEY)

  return sessionToken || localStorageToken
}

/**
 * Remove persisted authentication tokens.
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
const RemoveAuthToken = () => {
  window.localStorage.removeItem(AUTH_TOKEN_SETTING_STORAGE_KEY)
  window.sessionStorage.removeItem(AUTH_TOKEN_SETTING_STORAGE_KEY)
}

const AuthService = {
  LogIn,
  PersistAuthToken,
  GetAuthToken,
  RemoveAuthToken
}

export default AuthService
