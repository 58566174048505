import mainHTTPClient from '../clients/mainHTTPClient'
import { MAIN_HTTP_API_ENDPOINTS } from '../constants/api/mainHttp'
import { BROWSER_NOTIFICATION_PERMISSION_VALUES } from '../constants/general'

const BROWSER_NOTIFICATION_SETTING_LOCAL_STORAGE_KEY = 'notificationsTurnedOff'
const THEME_SETTING_LOCAL_STORAGE_KEY = 'theme'

/**
 * Get profile data of logged in user.
 * @returns {Promise<AxiosResponse<object>>}: profile data.
 * @constructor
 */
const GetProfileInfo = async () => {
  const profileInfo = await mainHTTPClient.get(
    MAIN_HTTP_API_ENDPOINTS.PROFILE
  )
  let browserNotificationsTurnedOff = window.localStorage.getItem(BROWSER_NOTIFICATION_SETTING_LOCAL_STORAGE_KEY)
  browserNotificationsTurnedOff &&= JSON.parse(browserNotificationsTurnedOff)
  profileInfo.data.browserNotification = window.Notification.permission === BROWSER_NOTIFICATION_PERMISSION_VALUES.GRANTED && !browserNotificationsTurnedOff
  profileInfo.data.theme = JSON.parse(window.localStorage.getItem(THEME_SETTING_LOCAL_STORAGE_KEY))

  return profileInfo
}

/**
 * Update logged-in user's profile data.
 * @param profileData {object}
 * @returns {Promise<AxiosResponse<any>>}: updated profile data.
 * @constructor
 */
const Update = async profileData => {
  const updateResult = await mainHTTPClient.put(
    MAIN_HTTP_API_ENDPOINTS.PROFILE,
    profileData
  )

  let notificationsAllowed = profileData.browserNotification
  if (profileData.browserNotification) {
    if (window.Notification.permission !== BROWSER_NOTIFICATION_PERMISSION_VALUES.GRANTED) {
      const notificationRequestResult = await window.Notification.requestPermission()
      notificationsAllowed = notificationRequestResult === BROWSER_NOTIFICATION_PERMISSION_VALUES.GRANTED
    }
  }

  window.localStorage.setItem(
    BROWSER_NOTIFICATION_SETTING_LOCAL_STORAGE_KEY,
    JSON.stringify(!notificationsAllowed)
  )
  window.localStorage.setItem(
    THEME_SETTING_LOCAL_STORAGE_KEY,
    JSON.stringify(profileData.theme)
  )

  return updateResult
}

/**
 * Delete logged-in user's profile.
 * @returns {Promise<AxiosResponse<string>>}: OK in case the deletion was successful.
 * @constructor
 */
const Delete = async () => mainHTTPClient.delete(
  MAIN_HTTP_API_ENDPOINTS.PROFILE
)

const ProfileService = {
  GetProfileInfo,
  Update,
  Delete
}

export default ProfileService
