import GloballyExcludedClickerDataStoreSelectors from '../selectors/data/GloballyExcludedClickers'
import GloballyExcludedClickerDataStoreSlice from '../slices/data/GloballyExcludedClickerDataStoreSlice'
import StoreGloballyExcludedClicker from '../models/GloballyExcludedClicker'
import useDataStoreItems from './helpers/useDataStoreItems'

const JSONStoreGloballyExcludedClickerToStoreGloballyExcludedClicker = JSONStoreGloballyExcludedClicker => StoreGloballyExcludedClicker.FromJSON(JSONStoreGloballyExcludedClicker)

/**
 * Returns requested-/all globallyExcludedClicker(s) from state.data.globallyExcludedClickers.
 * @param globallyExcludedClickerIds {string | string[]}: id of requested globallyExcludedClickers.
 * @param asMap {boolean}: when multiple globallyExcludedClickers are requested, return globallyExcludedClickers as an object mapping globallyExcludedClickers by their id.
 * @param fetchAllEnabled {boolean}: enable fetching of all globallyExcludedClickers, when no globallyExcludedClicker id is passed.
 * @returns {StoreGloballyExcludedClicker | StoreGloballyExcludedClicker[]}
 */
const useNormalizedGloballyExcludedClickers = (
  globallyExcludedClickerIds = null,
  asMap = false,
  fetchAllEnabled = false
) => useDataStoreItems(
  globallyExcludedClickerIds,
  GloballyExcludedClickerDataStoreSelectors.globallyExcludedClickers,
  GloballyExcludedClickerDataStoreSlice.actions.fetch,
  JSONStoreGloballyExcludedClickerToStoreGloballyExcludedClicker,
  asMap,
  fetchAllEnabled
)

export default useNormalizedGloballyExcludedClickers
