import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import DisplayAppCrashUI from './DisplayAppCrash.UI'
import { IS_TEST_ENV } from '../../../constants/general'

const DisplayAppCrash = ({ errorReference }) => (
  <DisplayAppCrashUI
    title={IS_TEST_ENV
      ? 'Something went wrong :('
      : (
        <Trans key='AppCrashTitle'>
          Something went wrong :(
        </Trans>
        )}
    errorReference={errorReference}
  />
)

DisplayAppCrash.propTypes = {
  /** Reference id of the error, that caused the crash. **/
  errorReference: PropTypes.string.isRequired
}

export default DisplayAppCrash
