import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../modules/routing/config/constants'
import './NotFound.css'
import { useTranslation } from 'react-i18next'

const NotFoundRoute = () => {
  const navigate = useNavigate()

  const { t } = useTranslation()

  const navigateToMainPage = useCallback(
    e => {
      e.preventDefault()
      navigate(ROUTES.ABSOLUTE_PATH, { replace: false })
    },
    [navigate]
  )

  return (
    <div id='notfound'>
      <div className='notfound'>
        <div className='notfound-404'>
          <h1>404</h1>
        </div>
        <h2>{t('NotFoundScreen.title', 'Oops! Nothing was found')}</h2>
        <p>
          {t('NotFoundScreen.description', 'The page you are looking for might have been removed had its name changed or is temporarily unavailable. ')}
          <a
            href={ROUTES.ABSOLUTE_PATH}
            onClick={navigateToMainPage}
          >
            {t('NotFoundScreen.returnToHomeButtonLabel', 'Return to homepage')}
          </a>
        </p>
      </div>
    </div>
  )
}

export default NotFoundRoute
