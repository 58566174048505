import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import YupInputSchemaBuilder from '../utilities/yupValidationHelpers'

/**
 * Hook that creates a YupInputSchemaBuilder instance and injects the translation system into it.
 * Creates a new YupInputSchemaBuilder instance every time the current locale is changed.
 * @returns {YupInputSchemaBuilder}: input schema builder.
 */
const useYupInputSchemaBuilder = () => {
  const { t } = useTranslation()

  return useMemo(() => new YupInputSchemaBuilder(t), [t])
}

export default useYupInputSchemaBuilder
