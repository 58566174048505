import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'

const Container = styled(Grid)(
  ({ theme }) => ({
    color: theme.palette.error.main,
    height: '100vh'
  })
)

const Title = styled('h2')({
  textAlign: 'center'
})

const Ref = styled('h3')({
  textAlign: 'center'
})

const DisplayAppCrashUI = ({ title, errorReference }) => (
  <Container
    container
    spacing={1}
    alignItems='center'
  >
    <Grid item xs={12} flex={0}>
      <Title>
        {title}
      </Title>
      <Ref>
        Ref: {errorReference}
      </Ref>
    </Grid>
  </Container>
)

DisplayAppCrashUI.propTypes = {
  /** Crash title **/
  title: PropTypes.node.isRequired,
  /** Reference id of the error, that caused the crash. **/
  errorReference: PropTypes.string.isRequired
}

export default DisplayAppCrashUI
