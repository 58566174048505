import { makeActionCreator } from '../../../modules/utilities/store'
import AUTH_EXPERIENCE_TYPES from '../../types/experiences/Auth'

const AuthExperienceActions = {
  /**
   * @param email {string}
   * @param password {string}
   * @returns {{ type: string, data: { email: string, password: string } }}
   */
  logIn: makeActionCreator(AUTH_EXPERIENCE_TYPES.LOG_IN, 'email', 'password'),
  /**
   * @returns {{ type: string, data: {} }}
   */
  logOut: makeActionCreator(AUTH_EXPERIENCE_TYPES.LOG_OUT),
  /**
   * @returns {{ type: string, data: {} }}
   */
  auth0Login: makeActionCreator(AUTH_EXPERIENCE_TYPES.AUTH0_LOGIN),
  /**
   * @returns {{ type: string, data: {} }}
   */
  auth0Logout: makeActionCreator(AUTH_EXPERIENCE_TYPES.AUTH0_LOGOUT),
  /**
   * @returns {{ type: string, data: {} }}
   */
  validateAuth0Login: makeActionCreator(AUTH_EXPERIENCE_TYPES.VALIDATE_AUTH0_LOGIN),
  /**
   * @returns {{ type: string, data: {} }}
   */
  verifyAuthentication: makeActionCreator(AUTH_EXPERIENCE_TYPES.VERIFY_AUTHENTICATION),
  /**
   * @param isAuthenticated {boolean}
   * @returns {{ type: string, data: { isAuthenticated: boolean } }}
   */
  setIsAuthenticated: makeActionCreator(AUTH_EXPERIENCE_TYPES.SET_IS_AUTHENTICATED, 'isAuthenticated'),
  /**
   * @param authenticationChecked {boolean}
   * @returns {{ type: string, data: { authenticationChecked: boolean } }}
   */
  setAuthenticationChecked: makeActionCreator(AUTH_EXPERIENCE_TYPES.SET_AUTH_CHECKED, 'authenticationChecked'),
  /**
   * @param authToken {string}
   * @param rememberToken {boolean}
   * @returns {{ type: string, data: { authToken: string, rememberToken: boolean } }}
   */
  persistAuthToken: makeActionCreator(AUTH_EXPERIENCE_TYPES.PERSIST_AUTH_TOKEN, 'authToken', 'rememberToken'),
  /**
   * Removes the auth token from everywhere(redux store and storages).
   * @returns {{ type: string, data: {} }}
   */
  removeAuthToken: makeActionCreator(AUTH_EXPERIENCE_TYPES.REMOVE_AUTH_TOKEN),
  /**
   * @param onValidationSuccess {function}
   * @returns {{ type: string, data: { onValidationSuccess: function } }}
   */
  validateAuthToken: makeActionCreator(AUTH_EXPERIENCE_TYPES.VALIDATE_AUTH_TOKEN, 'onValidationSuccess'),
  /**
   * @param authCodePrefix {string}
   * @returns {{ type: string, data: { authCodePrefix: string } }}
   */
  setAuthCodePrefix: makeActionCreator(AUTH_EXPERIENCE_TYPES.SET_AUTH_CODE_PREFIX, 'authCodePrefix'),
  /**
   * @returns {{ type: string, data: {} }}
   */
  fetchAuthCodePrefix: makeActionCreator(AUTH_EXPERIENCE_TYPES.FETCH_AUTH_CODE_PREFIX),
  /**
   * @param authCodePrefix {string}
   * @returns {{ type: string, data: { authCodePrefix: string } }}
   */
  persistAuthCodePrefix: makeActionCreator(AUTH_EXPERIENCE_TYPES.PERSIST_AUTH_CODE_PREFIX, 'authCodePrefix'),
  /**
   * @returns {{ type: string, data: {} }}
   */
  clearAuthCodePrefix: makeActionCreator(AUTH_EXPERIENCE_TYPES.CLEAR_AUTH_CODE_PREFIX),
  /**
   * @param authCodePrefixFetched {string}
   * @returns {{ type: string, data: { authCodePrefixFetched: string } }}
   */
  setAuthCodePrefixFetched: makeActionCreator(
    AUTH_EXPERIENCE_TYPES.SET_AUTH_CODE_PREFIX_FETCHED,
    'authCodePrefixFetched'
  ),
  /**
   * @param authCode {string}
   * @param rememberLogin {boolean}
   * @param onLoginSuccess {function}
   * @returns {{ type: string, data: { authCode: string, rememberLogin: boolean, onLoginSuccess: function } }}
   */
  validateAuthCode: makeActionCreator(
    AUTH_EXPERIENCE_TYPES.VALIDATE_AUTH_CODE,
    'authCode',
    'rememberLogin',
    'onLoginSuccess'
  ),
  /**
   * @param profile {object}
   * @returns {{ type: string, data: { profile: object } }}
   */
  setProfile: makeActionCreator(AUTH_EXPERIENCE_TYPES.SET_PROFIlE, 'profile'),
  /**
   * @param profile {StoreProfile}: StoreProfile instance containing edited profile data.
   * @param onSuccess {function}: executed if editing was successful.
   * @returns {{ type: string, data: { profile: StoreProfile, onSuccess: function } }}
   */
  editProfile: makeActionCreator(AUTH_EXPERIENCE_TYPES.EDIT_PROFIlE, 'profile', 'onSuccess')
}

export default AuthExperienceActions
