const LAYOUT_TYPES = {
  SET_APPBAR_TITLE: '[layout] setAppBarTitle',
  SET_IS_LOADING: '[layout] setIsLoading',
  SET_DRAWER_MENU_OPEN: '[layout] setDrawerMenuOpen',
  SET_CHAT_DRAWER_OPEN: '[layout] setChatDrawerOpen',
  SET_SERVICE_DRAWER_OPEN: '[layout] setServiceDrawerOpen',
  SET_FRAMELESS_MODE_TURNED_ON: '[layout] setFramelessModeTurnedOn'
}

export default LAYOUT_TYPES
