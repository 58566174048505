import { makeActionCreator } from '../../modules/utilities/store'
import THEME_TYPES from '../types/Theme'

export const setSelectedTheme = makeActionCreator(THEME_TYPES.SELECT, 'selectedTheme')

const ThemeActions = {
  /**
   * @param selectedThemeName {string}: selected theme's name.
   * @returns {{ type: string, data: { selectedThemeName: string } }}
   */
  selectTheme: makeActionCreator(THEME_TYPES.SELECT, 'selectedThemeName')
}

export default ThemeActions
