const selectApiKeys = state => state.data.apiKeys

const selectApiKeyById = apiKeyId => state => state.data.apiKeys[apiKeyId]

const ApiKeyDataStoreSelectors = {
  apiKeys: selectApiKeys,
  apiKeyById: selectApiKeyById
}

export default ApiKeyDataStoreSelectors
