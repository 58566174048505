import { combineReducers } from 'redux'
import AC from './AC'
import UIStore from './UI'
import DataStore from './data'
import ExperiencesStore from './experiences'

const reducers = () => combineReducers({
  ui: UIStore,
  ac: AC,
  data: DataStore,
  experiences: ExperiencesStore
})

export default reducers
