import RESTModelNormalizationSchemas from './models/RESTModelNormalizationSchemas'
import StoreLanguage from './models/Language'
import StoreProfile from './models/Profile'
import StoreUser from './models/User'
import StoreLink from './models/Link'
import StoreClick from './models/Click'
import StoreApiKey from './models/ApiKey'
import StoreGloballyExcludedClicker from './models/GloballyExcludedClicker'

export const NORMALIZATION_ENTITY_NAME_TO_STORE_ENTITY_NAME_MAPPING = {
  [RESTModelNormalizationSchemas.language.entityName]: StoreLanguage,
  [RESTModelNormalizationSchemas.profile.entityName]: StoreProfile,
  [RESTModelNormalizationSchemas.user.entityName]: StoreUser,
  [RESTModelNormalizationSchemas.link.entityName]: StoreLink,
  [RESTModelNormalizationSchemas.click.entityName]: StoreClick,
  [RESTModelNormalizationSchemas.apiKey.entityName]: StoreApiKey,
  [RESTModelNormalizationSchemas.globallyExcludedClicker.entityName]: StoreGloballyExcludedClicker
}
