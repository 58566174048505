import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import ChainedBackend from 'i18next-chained-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import I18NextHttpBackend from 'i18next-http-backend'
import I18NextFSBackend from 'i18next-fs-backend'
import I18NextLocalStorageBackend from 'i18next-localstorage-backend'
import { VALUE_FORMAT_TYPES } from './constants'
import { customDateFormatFunctions } from '../utilities/date'
import { IS_DEV_ENV, IS_TEST_ENV } from '../../constants/general'

const initIntl = () => i18n
  // loads translations from public/locales/**
  .use(ChainedBackend)
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: 'en-GB',
    saveMissing: true, // send not translated keys to save endpoint
    defaultNS: 'translation',
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        if (value instanceof Date) {
          const formatterName = `${format}_${lng}`
          const formatter = customDateFormatFunctions[formatterName]
          if (!formatter && process.env.NODE_ENV === 'development') {
            console.warn(`[Date value not formatted]: No formatter function was found with the name: "${formatterName}". 
You can implement the formatter function by adding a new prop to the variable "customDateFormatFunctions" inside "src/utilities/date.js".
The naming convention you have to follow is {formatType}_{language}.`)
            return value
          }

          return formatter(value)
        } else {
          if (format === VALUE_FORMAT_TYPES.TWO_DIGITS_FIXED_FLOAT_NUMBER) {
            return parseFloat(value).toFixed(2)
          }
        }

        return value
      }
    },
    backend: {
      backends: [
        I18NextLocalStorageBackend,
        // use the filesystem backend when running tests
        IS_TEST_ENV
          ? I18NextFSBackend
          : I18NextHttpBackend
      ],
      backendOptions: [
        {
          expirationTime: IS_DEV_ENV
            ? 0 // 0 ms expiration time in development
            : 7 * 24 * 60 * 60 * 1000 // 7 days - expiration time of cached translation files
        },
        IS_TEST_ENV
          ? {
              loadPath: '/public/locales/{{lng}}/{{ns}}.json' // path to translation files on backend
            }
          : {
              loadPath: '/locales/{{lng}}/{{ns}}.json', // path to translation files on backend
              crossDomain: true, // allow cross-domain list because the save server is on a different port
              // save service(saves new translation keys through the provided service)
              addPath: IS_DEV_ENV
                ? 'http://localhost:3001/locales/add/{{lng}}/{{ns}}'
                : undefined // turn off saving in production
            }
      ]
    }
  })

export default initIntl
