class StoreClick {
  constructor (
    id = undefined,
    linkId,
    clickedBy,
    ip,
    geocodedAddress,
    fromMobile,
    fromDesktop,
    os,
    browser,
    createdAt
  ) {
    this.id = id
    this.linkId = linkId
    this.clickedBy = clickedBy
    this.ip = ip
    this.geocodedAddress = geocodedAddress
    if (typeof geocodedAddress === 'object') {
      const coordinates = geocodedAddress.loc.split(',')
      this.latitude = parseFloat(coordinates[0])
      this.longitude = parseFloat(coordinates[1])
    }
    this.fromMobile = fromMobile
    this.fromDesktop = fromDesktop
    this.os = os
    this.browser = browser
    this.createdAt = createdAt
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      // linkId is read-only
      // clickedBy is read-only
      // ip is read-only
      // geocodedAddress is read-only
      // fromMobile is read-only
      // fromDesktop is read-only
      // os is read-only
      // browser is read-only
      // createdAt is read-only
    })
  }

  static FromNormalizedRESTModel ({
    id,
    linkId,
    clickedBy,
    ip,
    geocodedAddress,
    fromMobile,
    fromDesktop,
    os,
    browser,
    createdAt
  }) {
    let displayedGeocodedAddress = geocodedAddress
    try {
      displayedGeocodedAddress = JSON.parse(geocodedAddress)
    } catch (e) {}

    return new StoreClick(
      id,
      linkId,
      clickedBy,
      ip,
      displayedGeocodedAddress,
      fromMobile,
      fromDesktop,
      os,
      browser,
      createdAt
    )
  }

  toJSON () {
    return {
      id: this.id,
      linkId: this.linkId,
      clickedBy: this.clickedBy,
      ip: this.ip,
      geocodedAddress: this.geocodedAddress,
      fromMobile: this.fromMobile,
      fromDesktop: this.fromDesktop,
      os: this.os,
      browser: this.browser,
      createdAt: this.createdAt
    }
  }

  static FromJSON ({
    id,
    linkId,
    clickedBy,
    ip,
    geocodedAddress,
    fromMobile,
    fromDesktop,
    os,
    browser,
    createdAt
  }) {
    return new StoreClick(
      id,
      linkId,
      clickedBy,
      ip,
      geocodedAddress,
      fromMobile,
      fromDesktop,
      os,
      browser,
      createdAt
    )
  }
}

export default StoreClick
