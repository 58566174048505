import useNormalizedLanguages from './useNormalizedLanguages'
import useRoles from './useRoles'
import { useMemo } from 'react'
import useNormalizedProfile from './useNormalizedProfile'

/**
 * Logged in user's profile.
 * @returns {StoreProfile | null}
 */
const useProfile = () => {
  const normalizedProfile = useNormalizedProfile()
  const languages = useNormalizedLanguages(null, true)
  const roles = useRoles(null, true)

  return useMemo(
    () => {
      if (normalizedProfile) {
        normalizedProfile.role = roles[normalizedProfile.roleId]
        normalizedProfile.language = languages[normalizedProfile.languageId]
      }

      return normalizedProfile
    },
    [normalizedProfile, languages, roles]
  )
}

export default useProfile
