import { commonHandlers, createReducer } from '../../../modules/utilities/store'
import AUTH_EXPERIENCE_TYPES from '../../types/experiences/Auth'
import GLOBAL_TYPES from '../../types/Global'

const initialState = {
  profile: null,
  isAuthenticated: false,
  authenticationChecked: false,
  authCodePrefix: null,
  authCodePrefixFetched: false
}

const handlers = {
  [AUTH_EXPERIENCE_TYPES.SET_PROFIlE]: commonHandlers.setValue('profile'),
  [AUTH_EXPERIENCE_TYPES.SET_IS_AUTHENTICATED]: commonHandlers.setValue('isAuthenticated'),
  [AUTH_EXPERIENCE_TYPES.SET_AUTH_CHECKED]: commonHandlers.setValue('authenticationChecked'),
  [AUTH_EXPERIENCE_TYPES.SET_AUTH_CODE_PREFIX]: commonHandlers.setValue('authCodePrefix'),
  [AUTH_EXPERIENCE_TYPES.SET_AUTH_CODE_PREFIX_FETCHED]: commonHandlers.setValue('authCodePrefixFetched'),
  [GLOBAL_TYPES.RESET_STORE]: commonHandlers.resetState(initialState)
}

const AuthStore = createReducer(initialState, handlers)

export default AuthStore
