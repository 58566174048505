import RESTModelNormalizationSchemas from './models/RESTModelNormalizationSchemas'
import LanguageDataStoreSlice from './slices/data/LanguageDataStoreSlice'
import UserDataStoreSlice from './slices/data/UserDataStoreSlice'
import LinkDataStoreSlice from './slices/data/LinkDataStoreSlice'
import ClickDataStoreSlice from './slices/data/ClickDataStoreSlice'
import ApiKeyDataStoreSlice from './slices/data/ApiKeyDataStoreSlice'
import GloballyExcludedClickerDataStoreSlice from './slices/data/GloballyExcludedClickerDataStoreSlice'

/** Data-store slices mapped by their associated store normalization entity name  */
export const NORMALIZATION_ENTITY_NAME_TO_DATA_STORE_SLICE_MAPPING = {
  [RESTModelNormalizationSchemas.language.entityName]: LanguageDataStoreSlice,
  [RESTModelNormalizationSchemas.user.entityName]: UserDataStoreSlice,
  [RESTModelNormalizationSchemas.link.entityName]: LinkDataStoreSlice,
  [RESTModelNormalizationSchemas.click.entityName]: ClickDataStoreSlice,
  [RESTModelNormalizationSchemas.apiKey.entityName]: ApiKeyDataStoreSlice,
  [RESTModelNormalizationSchemas.globallyExcludedClicker.entityName]: GloballyExcludedClickerDataStoreSlice
}
