import { useDispatch, useSelector } from 'react-redux'
import LayoutSelectors from '../selectors/Layout'
import { useCallback } from 'react'
import LayoutActions from '../actions/Layout'

/**
 * Get/Set state.ui.layout.framelessModeTurnedOn
 * @returns {[framelessModeTurnedOn: boolean, setFramelessModeTurnedOn: function(boolean)]} state.ui.layout.appBarTitle
 */
const useFramelessModeTurnedOn = () => {
  const framelessModeTurnedOn = useSelector(LayoutSelectors.framelessModeTurnedOn)
  const dispatch = useDispatch()

  const setFramelessModeTurnedOn = useCallback(
    /**
     * @param framelessModeTurnedOn {boolean}
     * @returns {*}
     */
    framelessModeTurnedOn => dispatch(
      LayoutActions.setFramelessModeTurnedOn(framelessModeTurnedOn)
    ),
    [dispatch]
  )

  return [framelessModeTurnedOn, setFramelessModeTurnedOn]
}

export default useFramelessModeTurnedOn
