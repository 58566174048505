import { useMemo } from 'react'
import useRoles from './useRoles'
import { useTranslation } from 'react-i18next'

const roleToSelectOption = ({ id, name }) => ({
  value: id,
  label: name
})

/**
 * Use roles as select/radio input options
 * @param includeAllRoles {boolean}: whether to include the 'All roles' option
 * @returns {[{value: any, label: string}]}
 */
const useRolesAsSelectOptions = (includeAllRoles = false) => {
  const roles = useRoles()
  const { t } = useTranslation()

  return useMemo(() => {
    const roleSelectOptions = roles.map(roleToSelectOption)
    return includeAllRoles
      ? ([
          {
            value: '',
            label: t('RoleSelectOptions.Labels.AllRoles', 'ALL ROLES')
          },
          ...roleSelectOptions
        ])
      : roleSelectOptions
  },
  [t, roles, includeAllRoles]
  )
}

export default useRolesAsSelectOptions
