const selectClicks = state => state.data.clicks

const selectClickById = clickId => state => state.data.clicks[clickId]

const ClickDataStoreSelectors = {
  clicks: selectClicks,
  clickById: selectClickById
}

export default ClickDataStoreSelectors
