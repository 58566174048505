import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import AuthExperienceSelectors from '../selectors/experiences/Auth'

const useAuthState = () => {
  const isAuthenticated = useSelector(AuthExperienceSelectors.isAuthenticated)
  const authenticationChecked = useSelector(AuthExperienceSelectors.authChecked)
  const authCodePrefix = useSelector(AuthExperienceSelectors.authCodePrefix)
  const authCodePrefixFetched = useSelector(AuthExperienceSelectors.authCodePrefixFetched)

  return useMemo(
    () => ({
      isAuthenticated,
      authenticationChecked,
      authCodePrefix,
      authCodePrefixFetched
    }),
    [authenticationChecked, isAuthenticated, authCodePrefix, authCodePrefixFetched]
  )
}

export default useAuthState
