import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import LinkDataStoreSagaHandlers from '../../sagas/data/LinkDataStore'

const sliceName = 'linksStore'

const LinkDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  LinkDataStoreSagaHandlers
)

export default LinkDataStoreSlice
