import { useMemo } from 'react'
import useNormalizedLanguages from './useNormalizedLanguages'

const languageToSelectOption = ({ id, name }) => ({
  value: id,
  label: name
})

const useLanguagesAsSelectOptions = () => {
  const languages = useNormalizedLanguages()

  return useMemo(() => languages.map(languageToSelectOption), [languages])
}

export default useLanguagesAsSelectOptions
