import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import UISelectors from '../selectors/UI'
import { useTranslation } from 'react-i18next'

const useAvailableThemeNamesAsSelectOptions = () => {
  const availableThemes = useSelector(UISelectors.availableThemeNames)
  const { t } = useTranslation()

  return useMemo(
    () => availableThemes?.map(themeName => ({
      value: themeName,
      label: t(`ThemeNames[${themeName}]`, themeName)
    })) || [],
    [availableThemes, t]
  )
}

export default useAvailableThemeNamesAsSelectOptions
