import PropTypes from 'prop-types'
import { AbacProvider } from 'react-abac'
import { useSelector } from 'react-redux'
import { selectAC } from '../../../redux/selectors/AC'
import storeHooks from '../../../redux/hooks'
import { useMemo } from 'react'

const AccessControlProvider = ({ children, loadingPlaceholder }) => {
  const { rulesPopulated, rules } = useSelector(selectAC)
  const profile = storeHooks.useProfile()

  const authUser = useMemo(
    () => ({
      id: profile?.id,
      roles: [profile?.roleId],
      permissions: [] // user permissions can be directly populated here and the other rbac and abac rules will be ignored
    }),
    [profile]
  )

  return rulesPopulated
    ? (
      <AbacProvider
        user={authUser}
        roles={authUser.roles}
        rules={rules}
        permissions={authUser.permissions}
      >
        {children}
      </AbacProvider>
      )
    : loadingPlaceholder || null
}

AccessControlProvider.propTypes = {
  children: PropTypes.node,
  loadingPlaceholder: PropTypes.node
}

export default AccessControlProvider
