import { combineReducers } from 'redux'
import LayoutStore from './Layout'
import MessagesStore from './Messages'
import ThemeStore from './Theme'

const UIStore = combineReducers({
  layout: LayoutStore,
  messages: MessagesStore,
  theme: ThemeStore
})

export default UIStore
