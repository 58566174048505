/**
 * qs parse options for parsing primitive values instead of strings
 * [Reference](https://github.com/ljharb/qs/issues/91#issuecomment-437926409)
 * **/
const parsePrimitiveValuesOptions = {
  decoder (str, decoder, charset) {
    const strWithoutPlus = str.replace(/\+/g, ' ')
    if (charset === 'iso-8859-1') {
      // unescape never throws, no try...catch needed:
      return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape)
    }

    if (/^(\d+|\d*\.\d+)$/.test(str)) {
      return parseFloat(str)
    }

    const keywords = {
      true: true,
      false: false,
      null: null,
      undefined
    }
    if (str in keywords) {
      return keywords[str]
    }

    let decodedURIComponent = strWithoutPlus
    // utf-8
    try {
      decodedURIComponent = decodeURIComponent(strWithoutPlus)
    } catch (e) {}

    if (/([0-9]){4}-([0-9]){2}-([0-9]){2}T([0-9]){2}:([0-9]){2}:([0-9]){2}\.([0-9]){3}.*$/.test(decodedURIComponent)) {
      return new Date(decodedURIComponent)
    }

    return decodedURIComponent
  }
}

const qsUtilities = {
  parsePrimitiveValuesOptions
}

export default qsUtilities
