import * as yup from 'yup'
import FORM_MANAGEMENT_CONSTANTS from '../constants'

export const numberWithEmptyStringAllowed = yup.number()
  .nullable()
  .transform(
    (value, originalValue) => typeof originalValue === 'string'
      ? originalValue.trim() === '' ? null : value
      : value
  )

/**
 * Contains helper functions for building yup validation schemas for common form inputs(email, radio, checkbox, etc...)
 */
export const YupInputSchemaHelpers = {
  /**
   * Builds a yup schema for general input validation.
   * @param translateFunction {function(key:string, defaultTranslation:string, formatOptions:object): string}: translator.
   * @param inputSchema {object}
   * @param isRequired {boolean}: marks input as required inside the schema.
   * @returns {object}: yup schema for general input validation.
   */
  General: (translateFunction, inputSchema, isRequired = true) => isRequired
    ? inputSchema.required(
      translateFunction('InputValidationMessages.required', 'This field is required.')
    )
    : inputSchema,
  /**
   * Builds a yup schema for text input validation.
   * @param translateFunction {function(key:string, defaultTranslation:string, formatOptions:object): string}: translator.
   * @param isRequired {boolean}: marks input as required inside the schema.
   * @param addCustomTextType {function(object): object}: you can add a specific type to the plain 'yup.string' schema,
   * by implementing this function(like email for ex.).
   * @returns {object}: yup schema for text input validation.
   */
  Text: (translateFunction, isRequired = true, addCustomTextType = schema => schema) => YupInputSchemaHelpers.General(
    translateFunction,
    addCustomTextType(
      yup.string(
        translateFunction('InputValidationMessages.text', 'Field must be a string.')
      )
    ),
    isRequired
  ),
  /**
   * Builds a yup schema for boolean input(ex. switch) validation.
   * @param translateFunction {function(key:string, defaultTranslation:string, formatOptions:object): string}: translator.
   * @param isRequired {boolean}: marks input as required inside the schema.
   * @returns {object}: yup schema for boolean input(ex. switch) validation.
   */
  Boolean: (translateFunction, isRequired = true) => YupInputSchemaHelpers.General(
    translateFunction,
    yup.boolean(
      translateFunction('InputValidationMessages.boolean', 'Field must have a boolean value("true" or "false").')
    ),
    isRequired
  ),
  /**
   * Builds a yup schema for date input validation.
   * @param translateFunction {function(key:string, defaultTranslation:string, formatOptions:object): string}: translator.
   * @param isRequired {boolean}: marks input as required inside the schema.
   * @returns {object}: yup schema for date input validation.
   */
  Date: (translateFunction, isRequired = true) => YupInputSchemaHelpers.General(
    translateFunction,
    yup.date(
      translateFunction('InputValidationMessages.date', 'Field must be a date.')
    ),
    isRequired
  ),
  /**
   * Builds a yup schema for input validation with a regular expression.
   * @param translateFunction {function(key:string, defaultTranslation:string, formatOptions:object): string}: translator.
   * @param regex {RegExp}: regular expression to match.
   * @param errorMessage {string}: validation message.
   * @param isRequired {boolean}: marks input as required inside the schema.
   * @returns {object}: yup schema for input validation with regex.
   */
  RegExp: (translateFunction, regex, errorMessage, isRequired = true) => YupInputSchemaHelpers.Text(
    translateFunction,
    isRequired,
    schema => schema.matches(
      regex,
      {
        message: errorMessage,
        excludeEmptyString: !isRequired
      }
    )
  ),
  /**
   * Builds a yup schema for email input validation.
   * @param translateFunction {function(key:string, defaultTranslation:string, formatOptions:object): string}: translator.
   * @param isRequired {boolean}: marks input as required inside the schema.
   * @returns {object}: yup schema for email input validation.
   */
  Email: (translateFunction, isRequired = true) => YupInputSchemaHelpers.Text(
    translateFunction,
    isRequired,
    schema => schema.email(
      translateFunction('InputValidationMessages.email', 'Please enter a valid email address!')
    )
  ),
  /**
   * Builds a yup schema for phone number input validation.
   * @param translateFunction {function(key:string, defaultTranslation:string, formatOptions:object): string}: translator.
   * @param isRequired {boolean}: marks input as required inside the schema.
   * @returns {object}: yup schema for phone number input validation.
   */
  PhoneNumber: (translateFunction, isRequired = true) => YupInputSchemaHelpers.RegExp(
    translateFunction,
    FORM_MANAGEMENT_CONSTANTS.PHONE_VALIDATION_PLUS_REQUIRED_REGEX,
    translateFunction('InputValidationMessages.phone', 'Please enter a valid phone number!'),
    isRequired
  ),
  /**
   * Builds a yup schema for "select" input validation.
   * @param translateFunction {function(key:string, defaultTranslation:string, formatOptions:object): string}: translator.
   * @param availableValues {[]}: values available to select from(all possible <option values).
   * @param isMultiSelect {boolean}: specify whether is it possible to select multiple values. Validation differs in
   * this case.
   * @param isRequired {boolean}: marks input as required inside the schema.
   * @param valueType {('string'|'number'|'object')}: value type accepted by the input(type of <option value ...).
   * @returns {object}: yup schema for "select" input validation.
   */
  Select: (translateFunction, availableValues = [], isMultiSelect = false, isRequired = true, valueType = 'string') => {
    let typeSchema
    switch (valueType) {
      case 'number':
        typeSchema = numberWithEmptyStringAllowed
        break
      case 'object':
        typeSchema = yup.object()
        break
      case 'string':
      default:
        typeSchema = yup.string()
        break
    }

    typeSchema = typeSchema.oneOf(
      isRequired
        ? availableValues
        : availableValues.concat(null),
      translateFunction(
        'InputValidationMessages.select',
        'Please select from these values {{availableValues}}',
        { availableValues }
      )
    )

    if (isMultiSelect) {
      typeSchema = yup.array().of(
        typeSchema
      )
      if (isRequired) {
        typeSchema = typeSchema.min(1, translateFunction('InputValidationMessages.required', 'This field is required.'))
      }
    }

    return YupInputSchemaHelpers.General(
      translateFunction,
      typeSchema,
      isRequired
    )
  },
  /**
   * Builds a yup schema for radio input validation.
   * @param translateFunction {function(key:string, defaultTranslation:string, formatOptions:object): string}: translator.
   * @param availableValues {[]}: values available to choose from(all possible <input type="radio" values inside the
   * radio group).
   * @param isRequired {boolean}: marks input as required inside the schema.
   * @param valueType {('string'|'number'|'object')}: value type accepted by the input(type of <input type="radio" value ...).
   * @returns {object}: yup schema for radio input validation.
   */
  Radio: (translateFunction, availableValues = [], isRequired = true, valueType = 'string') => {
    let typeSchema
    switch (valueType) {
      case 'number':
        typeSchema = numberWithEmptyStringAllowed
        break
      case 'object':
        typeSchema = yup.object()
        break
      case 'string':
      default:
        typeSchema = yup.string()
        break
    }

    return YupInputSchemaHelpers.General(
      translateFunction,
      typeSchema.oneOf(
        isRequired
          ? availableValues
          : availableValues.concat(null),
        translateFunction(
          'InputValidationMessages.radio',
          'Please select from these values {{availableValues}}',
          { availableValues }
        )
      ),
      isRequired
    )
  },
  /**
   * Builds a yup schema for checkbox input validation.
   * @param translateFunction {function(key:string, defaultTranslation:string, formatOptions:object): string}: translator.
   * @param availableValues {[]}: values available to choose from(all possible <input type="checkbox" values inside the
   * checkbox group).
   * @param isRequired {boolean}: marks input as required inside the schema.
   * @param valueType {('string'|'number'|'object')}: value type accepted by the input(type of <input type="checkbox" value ...).
   * @returns {object}: yup schema for checkbox input validation.
   */
  Checkbox: (translateFunction, availableValues = [], isRequired = true, valueType = 'string') => {
    let typeSchema
    switch (valueType) {
      case 'number':
        typeSchema = numberWithEmptyStringAllowed
        break
      case 'object':
        typeSchema = yup.object()
        break
      case 'string':
      default:
        typeSchema = yup.string()
        break
    }

    typeSchema = yup.array().of(
      typeSchema.oneOf(
        isRequired
          ? availableValues
          : availableValues.concat(null),
        translateFunction(
          'InputValidationMessages.checkbox',
          'Please select from these values {{availableValues}}',
          { availableValues }
        )
      )
    )

    if (isRequired) {
      typeSchema = typeSchema.min(1, translateFunction('InputValidationMessages.required', 'This field is required.'))
    }

    return YupInputSchemaHelpers.General(
      translateFunction,
      typeSchema,
      isRequired
    )
  }
}

/**
 * YupInputSchemaBuilder: Usability wrapper around YupInputSchemaHelpers.
 * Encapsulates translation, so it's not required to pass translateFunction to the input schema helpers, every time
 * a schema is built.
 * @param translateFunction {function(key:string, defaultTranslation:string, formatOptions:object): string}
 */
function YupInputSchemaBuilder (translateFunction) {
  this.t = translateFunction
}

YupInputSchemaBuilder.prototype = {
  /**
   * Builds a yup schema for general input validation.
   * @param inputSchema {object}
   * @param isRequired {boolean}: marks input as required inside the schema.
   * @returns {object}: yup schema for general input validation.
   */
  General (inputSchema, isRequired = true) {
    return YupInputSchemaHelpers.General(
      this.t,
      inputSchema,
      isRequired
    )
  },
  /**
   * Builds a yup schema for text input validation.
   * @param isRequired {boolean}: marks input as required inside the schema.
   * @param addCustomTextType {function(object): object}: you can add a specific type to the plain 'yup.string' schema,
   * by implementing this function(like email, minLength, for ex.).
   * @returns {object}: yup schema for text input validation.
   */
  Text (isRequired = true, addCustomTextType = schema => schema) {
    return YupInputSchemaHelpers.Text(
      this.t,
      isRequired,
      addCustomTextType
    )
  },
  /**
   * Builds a yup schema for boolean input(ex. switch) validation.
   * @param isRequired {boolean}: marks input as required inside the schema.
   * @returns {object}: yup schema for boolean input(ex. switch) validation.
   */
  Boolean (isRequired = true) {
    return YupInputSchemaHelpers.Boolean(
      this.t,
      isRequired
    )
  },
  /**
   * Builds a yup schema for date input validation.
   * @param isRequired {boolean}: marks input as required inside the schema.
   * @returns {object}: yup schema for date input validation.
   */
  Date (isRequired = true) {
    return YupInputSchemaHelpers.Date(
      this.t,
      isRequired
    )
  },
  /**
   * Builds a yup schema for text input validation with a regular expression.
   * @param regularExpression {RegExp}: regular expression to match.
   * @param errorMessage {string}: validation message.
   * @param isRequired {boolean}: marks input as required inside the schema.
   * @returns {object}: yup schema for text input validation with a regular expression.
   */
  RegExp (regularExpression, errorMessage, isRequired = true) {
    return YupInputSchemaHelpers.RegExp(
      this.t,
      regularExpression,
      errorMessage,
      isRequired
    )
  },
  /**
   * Builds a yup schema for email input validation.
   * @param isRequired {boolean}: marks input as required inside the schema.
   * @returns {object}: yup schema for email input validation.
   */
  Email (isRequired = true) {
    return YupInputSchemaHelpers.Email(
      this.t,
      isRequired
    )
  },
  /**
   * Builds a yup schema for phone number input validation.
   * @param isRequired {boolean}: marks input as required inside the schema.
   * @returns {object}: yup schema for phone number input validation.
   */
  PhoneNumber (isRequired = true) {
    return YupInputSchemaHelpers.PhoneNumber(
      this.t,
      isRequired
    )
  },
  /**
   * Builds a yup schema for "select" input validation.
   * @param availableValues {[]}: values available to select from(all possible <option values).
   * @param isMultiSelect {boolean}: specify whether is it possible to select multiple values. Validation differs in
   * this case.
   * @param isRequired {boolean}: marks input as required inside the schema.
   * @param valueType {('string'|'number'|'object')}: value type accepted by the input(type of <option value ...).
   * @returns {object}: yup schema for "select" input validation.
   */
  Select (availableValues = [], isMultiSelect = false, isRequired = true, valueType = 'string') {
    return YupInputSchemaHelpers.Select(
      this.t,
      availableValues,
      isMultiSelect,
      isRequired,
      valueType
    )
  },
  /**
   * Builds a yup schema for radio input validation.
   * @param availableValues {[]}: values available to choose from(all possible <input type="radio" values inside the
   * radio group).
   * @param isRequired {boolean}: marks input as required inside the schema.
   * @param valueType {('string'|'number'|'object')}: value type accepted by the input(type of <input type="radio" value ...).
   * @returns {object}: yup schema for radio input validation.
   */
  Radio (availableValues = [], isRequired = true, valueType = 'string') {
    return YupInputSchemaHelpers.Radio(
      this.t,
      availableValues,
      isRequired,
      valueType
    )
  },
  /**
   * Builds a yup schema for checkbox input validation.
   * @param availableValues {[]}: values available to choose from(all possible <input type="checkbox" values inside the
   * checkbox group).
   * @param isRequired {boolean}: marks input as required inside the schema.
   * @param valueType {('string'|'number'|'object')}: value type accepted by the input(type of <input type="checkbox" value ...).
   * @returns {object}: yup schema for checkbox input validation.
   */
  Checkbox (availableValues = [], isRequired = true, valueType = 'string') {
    return YupInputSchemaHelpers.Checkbox(
      this.t,
      availableValues,
      isRequired,
      valueType
    )
  }
}

YupInputSchemaBuilder.prototype.constructor = YupInputSchemaBuilder

export default YupInputSchemaBuilder
