class StoreLanguage {
  constructor (
    id = undefined,
    name
  ) {
    this.id = id
    this.name = name
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      name: this.name
    })
  }

  static FromNormalizedRESTModel ({
    id,
    name
  }) {
    return new StoreLanguage(
      id,
      name
    )
  }

  toJSON () {
    return {
      id: this.id,
      name: this.name
    }
  }

  static FromJSON ({
    id,
    name
  }) {
    return new StoreLanguage(
      id,
      name
    )
  }
}

export default StoreLanguage
