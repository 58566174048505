import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import UserDataStoreSagaHandlers from '../../sagas/data/UserDataStore'

const sliceName = 'usersStore'

const UserDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  UserDataStoreSagaHandlers
)

export default UserDataStoreSlice
