import mainHTTPClient from '../clients/mainHTTPClient'
import { MAIN_HTTP_API_ENDPOINTS } from '../constants/api/mainHttp'

/**
 * Create a new user.
 * @param userData {object}: User data.
 * @returns {Promise<AxiosResponse<object>>}: newly created user.
 * @constructor
 */
const Create = async userData => mainHTTPClient.post(
  MAIN_HTTP_API_ENDPOINTS.USERS,
  userData
)

/**
 * Get data of specified user.
 * @param userId {string}
 * @returns {Promise<AxiosResponse<object>>}: user data.
 * @constructor
 */
const GetUserInfo = async userId => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.USER.replace(':id', userId)
)

/**
 * Update the specified user's data.
 * @param userId {string}
 * @param userData {object}
 * @returns {Promise<AxiosResponse<any>>}: updated user data.
 * @constructor
 */
const Update = async (userId, userData) => mainHTTPClient.put(
  MAIN_HTTP_API_ENDPOINTS.USER.replace(':id', userId),
  userData
)

/**
 * Delete specified user.
 * @param userId {string}
 * @returns {Promise<AxiosResponse<string>>}: OK in case the deletion was successful.
 * @constructor
 */
const Delete = async userId => mainHTTPClient.delete(
  MAIN_HTTP_API_ENDPOINTS.USER.replace(':id', userId)
)

/**
 *
 * Search for users.
 * @param listMetaData {ListMetaData}
 * @returns {Promise<AxiosResponse<object[]>>}
 * @constructor
 */
const FindMany = async listMetaData => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.USERS,
  {
    ...listMetaData.toAxiosRequestParams()
  }
)

const UserService = {
  Create,
  GetUserInfo,
  Update,
  Delete,
  FindMany
}

export default UserService
