import { LANGUAGES } from '../modules/ac/constants'

const languageItems = Object.keys(LANGUAGES).reduce(
  (languageItems, languageName) => {
    languageItems.push({
      id: LANGUAGES[languageName],
      name: languageName
    })
    return languageItems
  },
  []
)

/**
 * Search for languages.
 * @param listMetaData {ListMetaData}
 * @returns {Promise<AxiosResponse<object[]>>}
 * @constructor
 */
const FindMany = (/* listMetaData */) => ({
  data: {
    totalNrOfItems: languageItems.length,
    items: languageItems
  }
})

const LanguageService = {
  FindMany
}

export default LanguageService
