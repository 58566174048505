class StoreApiKey {
  constructor (
    id = undefined,
    alias,
    key,
    generatedForUserId,
    expiresAt,
    createdAt
  ) {
    this.id = id
    this.alias = alias
    this.key = key
    this.generatedForUserId = generatedForUserId
    this.expiresAt = expiresAt
    this.createdAt = createdAt
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      alias: this.alias
      // key is read-only
      // generatedForUserId is read-only
      // expiresAt is read-only
      // createdAt is read-only
    })
  }

  static FromNormalizedRESTModel ({
    id,
    alias,
    key,
    generatedForUserId,
    expiresAt,
    createdAt
  }) {
    return new StoreApiKey(
      id,
      alias,
      key,
      generatedForUserId,
      expiresAt,
      createdAt
    )
  }

  toJSON () {
    return {
      id: this.id,
      alias: this.alias,
      key: this.key,
      generatedForUserId: this.generatedForUserId,
      expiresAt: this.expiresAt,
      createdAt: this.createdAt
    }
  }

  static FromJSON ({
    id,
    alias,
    key,
    generatedForUserId,
    expiresAt,
    createdAt
  }) {
    return new StoreApiKey(
      id,
      alias,
      key,
      generatedForUserId,
      expiresAt,
      createdAt
    )
  }
}

export default StoreApiKey
