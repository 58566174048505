import { put, takeLatest } from 'redux-saga/effects'
import UserService from '../../../services/User'
import ErrorActions from '../../actions/Error'
import OLError from '../../../modules/errorHandling/models/OLError'
import USER_MANAGEMENT_EXPERIENCE_TYPES from '../../types/experiences/UserManagement'
import LayoutActions from '../../actions/Layout'
import MessageActions from '../../actions/Messages'
import { AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT, DISPLAYED_MESSAGE_TYPES } from '../../../constants/general'
import UserDataStoreSlice from '../../slices/data/UserDataStoreSlice'

function * createUser (action) {
  const fetchId = 'UserManagementExperienceSaga.createUser'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { userItem, onSuccess } } = action

    const result = yield UserService.Create(userItem.toNormalizedRESTModel())
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['CREATE_USER_SUCCESS', 'User successfully created'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
      onSuccess && onSuccess()
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[UserManagementExperience->createUser]: Failed to create user', 'Failed to create user', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * editUser (action) {
  const fetchId = 'UserManagementExperienceSaga.editUser'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { userItem, onSuccess } } = action

    const result = yield UserService.Update(userItem.id, userItem.toNormalizedRESTModel())
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['EDIT_USER_SUCCESS', 'User successfully updated'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
      onSuccess && onSuccess()
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[UserManagementExperience->editUser]: Failed to update user', 'Failed to update user', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * deleteUser (action) {
  const fetchId = 'UserManagementExperienceSaga.deleteUser'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { userId } } = action

    const result = yield UserService.Delete(userId)
    if (result.status === 200) {
      // delete item from the store
      yield put(
        UserDataStoreSlice.actions.deleteItem(userId)
      )
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['DELETE_USER_SUCCESS', 'User deleted'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[UserManagementExperience->deleteUser]: Failed to delete user', 'Failed to delete user', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * UserManagementExperienceSaga () {
  yield takeLatest(USER_MANAGEMENT_EXPERIENCE_TYPES.CREATE, createUser)
  yield takeLatest(USER_MANAGEMENT_EXPERIENCE_TYPES.EDIT, editUser)
  yield takeLatest(USER_MANAGEMENT_EXPERIENCE_TYPES.DELETE, deleteUser)
}

export default UserManagementExperienceSaga
