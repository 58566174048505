import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import { DISPLAYED_MESSAGE_TYPES } from '../../../constants/general'

const Message = ({ id, text, type, autoHideAfterSec, onClose }) => {
  const [isOpen, setIsOpen] = useState(true)

  const autoHideAfter = useMemo(() => autoHideAfterSec ? autoHideAfterSec * 1000 : undefined, [autoHideAfterSec])

  const handleClose = useCallback(
    () => {
      setIsOpen(false)
      onClose && onClose(id)
    },
    [id, onClose, setIsOpen]
  )

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={autoHideAfter}
      onClose={handleClose}
    >
      <MuiAlert
        elevation={6}
        variant='filled'
        severity={type}
      >
        {text}
      </MuiAlert>
    </Snackbar>
  )
}

Message.propTypes = {
  /**
   * Message id.
   */
  id: PropTypes.string,
  /**
   * Displayed message.
   */
  text: PropTypes.string.isRequired,
  /**
   * Message type.
   */
  type: PropTypes.oneOf(Object.values(DISPLAYED_MESSAGE_TYPES)).isRequired,
  /**
   * Auto-hide after @autoHideAfterSec seconds.
   */
  autoHideAfterSec: PropTypes.number,
  /**
   * Executed when the close button is clicked. Message id is passed as the first argument.
   */
  onClose: PropTypes.func
}

export default Message
