/**
 * Encapsulates sorting metadata management.
 * @param sortType {SortParameter.SORT_TYPES} sort type.
 * @param attributeName {string} the name of the sorted attribute.
 */
class SortParameter {
  constructor (sortType, attributeName) {
    const allowedSortTypes = Object.values(SortParameter.SORT_TYPES)

    if (!allowedSortTypes.includes(sortType)) {
      throw new Error(`[SortParameter] Invalid sort type parameter: ${sortType}`)
    }

    if (!attributeName) {
      throw new Error('[SortParameter] attributeName is required')
    }

    this.sortType = sortType
    this.attributeName = attributeName
  }

  serializeToRequestQueryParameter () {
    return `${SortParameter.SORT_TYPE_SERIALIZATION_MAPPING[this.sortType]}${this.attributeName}`
  }

  static ASC (attributeName) {
    return new SortParameter(SortParameter.SORT_TYPES.ASC, attributeName)
  }

  static DESC (attributeName) {
    return new SortParameter(SortParameter.SORT_TYPES.DESC, attributeName)
  }

  toJSON () {
    return {
      sortType: SortParameter.SORT_TYPE_SERIALIZATION_MAPPING[this.sortType],
      attributeName: this.attributeName
    }
  }

  static fromJSON (json) {
    return new SortParameter(
      SortParameter.SORT_TYPE_DESERIALIZATION_MAPPING[json.sortType],
      json.attributeName
    )
  }

  static FromRequestParam (requestParam) {
    const type = requestParam.substr(0, 1)
    const attributeName = requestParam.substring(1)

    return new SortParameter(
      SortParameter.SORT_TYPE_DESERIALIZATION_MAPPING[type],
      attributeName
    )
  }

  /**
   * Serializes a collection of sort parameters into a request parameter(Builds a multi-sort parameter).
   * @param sortParameterCollection {SortParameter[]}
   */
  static SerializeSortParameterCollectionToRequestParam (sortParameterCollection) {
    return sortParameterCollection.map(sortParameter => sortParameter.serializeToRequestQueryParameter()).join(',')
  }

  static SortParameterCollectionFromRequestParam (requestParam) {
    return requestParam.split(',').map(sortParameter => SortParameter.FromRequestParam(sortParameter))
  }

  static SerializeSortParameterCollectionToJSON (sortParameterCollection) {
    return sortParameterCollection.map(sortParameter => sortParameter.toJSON())
  }

  static SortParameterCollectionFromJSON (json) {
    return json.map(sortParameter => SortParameter.fromJSON(sortParameter))
  }

  static isJSONValid (json) {
    const jsonKeys = Object.keys(json)
    return jsonKeys.length === 2 && jsonKeys.includes('sortType') && jsonKeys.includes('attributeName')
  }
}

SortParameter.SORT_TYPES = {
  ASC: 'asc',
  DESC: 'desc'
}

SortParameter.SORT_TYPE_SERIALIZATION_MAPPING = {
  [SortParameter.SORT_TYPES.ASC]: '+',
  [SortParameter.SORT_TYPES.DESC]: '-'
}

SortParameter.SORT_TYPE_DESERIALIZATION_MAPPING = {
  [SortParameter.SORT_TYPE_SERIALIZATION_MAPPING[SortParameter.SORT_TYPES.ASC]]: SortParameter.SORT_TYPES.ASC,
  [SortParameter.SORT_TYPE_SERIALIZATION_MAPPING[SortParameter.SORT_TYPES.DESC]]: SortParameter.SORT_TYPES.DESC
}

export default SortParameter
