const selectAvailableThemeNames = ({ ui: { theme: { availableThemeNames } } }) => availableThemeNames

const selectSelectedThemeName = ({ ui: { theme: { selectedThemeName } } }) => selectedThemeName

const UISelectors = {
  availableThemeNames: selectAvailableThemeNames,
  selectedThemeName: selectSelectedThemeName
}

export default UISelectors
