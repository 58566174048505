import { makeActionCreator } from '../../../modules/utilities/store'
import DATA_STORES_TYPES from '../../types/data'

/**
 * Creates an action, that when dispatched, merges received normalized entities into their own data-stores.
 * @param entities {object}: normalized entities.
 * @returns {{type:string, data: {entities: object}}}:
 */
const mergeEntitiesIntoStores = makeActionCreator(DATA_STORES_TYPES.MERGE_ENTITIES_INTO_STORES, 'entities')

const DataStoresActions = {
  mergeEntitiesIntoStores
}

export default DataStoresActions
