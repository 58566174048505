import React from 'react'
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles'
import { useSelector } from 'react-redux'
import themes from '../../../modules/theming/themes'
import UISelectors from '../../../redux/selectors/UI'

const ThemeProvider = ({ children }) => {
  const selectedThemeName = useSelector(UISelectors.selectedThemeName)

  return (
    <MUIThemeProvider theme={themes[selectedThemeName]}>
      {children}
    </MUIThemeProvider>
  )
}

export default ThemeProvider
