import LINK_MANAGEMENT_EXPERIENCE_TYPES from '../../types/experiences/LinkManagement'
import { makeActionCreator } from '../../../modules/utilities/store'

const LinkManagementExperienceActions = {
  /**
   * @param linkItem {StoreLink}: StoreLink instance containing edited link data.
   * @param onSuccess {function}: executed if creation was successful.
   * @returns {{ type: string, data: { linkItem: StoreLink, onSuccess: function } }}
   */
  create: makeActionCreator(LINK_MANAGEMENT_EXPERIENCE_TYPES.CREATE, 'linkItem', 'onSuccess'),
  /**
   * @param linkItem {StoreLink}: StoreLink instance containing edited link data.
   * @param onSuccess {function}: executed if editing was successful.
   * @returns {{ type: string, data: { linkItem: StoreLink, onSuccess: function } }}
   */
  edit: makeActionCreator(LINK_MANAGEMENT_EXPERIENCE_TYPES.EDIT, 'linkItem', 'onSuccess'),
  /**
   * @param linkId {string}: id of link to delete.
   * @returns {{ type: string, data: { linkId: string } }}
   */
  delete: makeActionCreator(LINK_MANAGEMENT_EXPERIENCE_TYPES.DELETE, 'linkId'),
  /**
   * @param linkId {string}: id of link to fetch the clicks for.
   * @param onSuccess {function}: executed if fetching was successful.
   * @returns {{ type: string, data: { linkId: string, onSuccess: function } }}
   */
  fetchClicks: makeActionCreator(LINK_MANAGEMENT_EXPERIENCE_TYPES.FETCH_CLICKS, 'linkId', 'onSuccess')
}

export default LinkManagementExperienceActions
