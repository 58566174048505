import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, useLocation } from 'react-router-dom'
import { ROUTES } from '../../config/constants'
import storeHooks from '../../../../redux/hooks'
import LoadingBackdrop from '../../../../components/layout/LoadingBackdrop'
import PageLoadingSkeleton from '../../../../components/layout/PageLoadingSkeleton'

/**
 * Wrap route components with this component, if you want to allow your route to be accessed by only authenticated users.
 * @param children {JSX.Element|*}: restricted route component.
 * @param lazyLoaded {boolean}: pass `true`, when the route component is lazily loaded with `React.lazy`. Route will be
 * lazy loaded after login.
 * @param lazyLoadingFallback {JSX.Element}: Element displayed while the underlying route component is being loaded.
 * @returns {JSX.Element}
 */
const AuthBasedRouteRestrictor = ({ children, lazyLoaded = false, lazyLoadingFallback }) => {
  const { isAuthenticated, authenticationChecked } = storeHooks.useAuthState()
  const location = useLocation()

  // while the authentication is being checked, a loading animation is displayed
  if (!authenticationChecked) {
    return (
      <LoadingBackdrop />
    )
  }

  if (!isAuthenticated) {
    /** after the authentication was checked, and the authentication has failed, redirect to the login page, and save the
     * accessed location, so we can navigate back after login */
    return (
      <Navigate
        to={ROUTES.LOGIN.ABSOLUTE_PATH}
        state={{ from: location }}
        replace
      />
    )
  }

  // if user is correctly authenticated, display the requested route component
  return lazyLoaded
    ? (
      <React.Suspense
        fallback={lazyLoadingFallback || <PageLoadingSkeleton />}
      >
        {children}
      </React.Suspense>
      )
    : children
}

AuthBasedRouteRestrictor.propTypes = {
  lazyLoaded: PropTypes.bool,
  lazyLoadingFallback: PropTypes.element
}

export default AuthBasedRouteRestrictor
