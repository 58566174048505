import DATA_STORE_TYPES from './types'
import createDataStoreReducer from './reducer'
import createDataStoreActions from './actions'
import createDataStoreSagas from './sagas'

/**
 * Creates a redux store slice that manages data stores(normalized server data inside the redux store).
 * @param sliceName {string}: unique identifier of the slice.
 * @param initialState {object}: overwrite default initial state.
 * @param sagaHandlers {function(slice: object): { fetch: function({type: string, data: {ids: string[] | undefined, extraParams: any}}): Generator, fetchResult: function({type: string, data: {result: {}, callback: function(storeValue: object)}}): Generator, fetchId: string }}
 * @returns {{types: {FETCH: string, FETCH_RESULT: string, MERGE: string, ADD_ITEM: string, DELETE_ITEM: string, RESET: string}, reducer: ((function(*=, *): *)|*), actions: {fetch: function(ids: string[]): object, fetchResult: function(result: AxiosResponse<object[]>, callback: function(storeValue: object)): object, merge: function(data: {}): object, addItem: function(id: string, item: {}): object, deleteItem: function(id: string): object, reset: function(): object}, saga: ((function(): Generator<SimpleEffect<"FORK", ForkEffectDescriptor<never>>, void, *>)|*), fetchIds: { fetchId: string }}}
 */
const createDataStoreSlice = (sliceName, initialState = {}, sagaHandlers) => {
  const types = DATA_STORE_TYPES(sliceName)

  const slice = {
    reducer: createDataStoreReducer(types, initialState),
    actions: createDataStoreActions(types),
    types
  }

  const sagas = createDataStoreSagas(slice, types, sagaHandlers)
  slice.saga = sagas.saga
  slice.fetchIds = {
    ...sagas.fetchIds
  }

  return slice
}

export default createDataStoreSlice
