import LanguageService from '../../../services/Language'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const LanguageDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'language',
  LanguageService,
  RESTModelNormalizationSchemas.language.schema,
  [RESTModelNormalizationSchemas.language.entityName]
)

export default LanguageDataStoreSagaHandlers
