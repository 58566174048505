const PHONE_VALIDATION_REGEX = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const PHONE_VALIDATION_PLUS_REQUIRED_REGEX = /^\+((\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const FORM_MANAGEMENT_CONSTANTS = {
  PHONE_VALIDATION_REGEX,
  PHONE_VALIDATION_PLUS_REQUIRED_REGEX
}

export default FORM_MANAGEMENT_CONSTANTS
