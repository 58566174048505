const selectAppBarTitle = state => state.ui.layout.appBarTitle

const selectLoading = state => state.ui.layout.loading

const selectFetchIds = state => state.ui.layout.fetchIds

const selectDrawerMenuOpen = state => state.ui.layout.drawerMenuOpen

const selectChatDrawerOpen = state => state.ui.layout.chatDrawerOpen

const selectServiceDrawerOpen = state => state.ui.layout.serviceDrawerOpen

const selectFramelessModeTurnedOn = state => state.ui.layout.framelessModeTurnedOn

const LayoutSelectors = {
  appBarTitle: selectAppBarTitle,
  loading: selectLoading,
  fetchIds: selectFetchIds,
  drawerMenuOpen: selectDrawerMenuOpen,
  chatDrawerOpen: selectChatDrawerOpen,
  serviceDrawerOpen: selectServiceDrawerOpen,
  framelessModeTurnedOn: selectFramelessModeTurnedOn
}

export default LayoutSelectors
