import UserDataStoreSelectors from '../selectors/data/Users'
import UserDataStoreSlice from '../slices/data/UserDataStoreSlice'
import StoreUser from '../models/User'
import useDataStoreItems from './helpers/useDataStoreItems'

const JSONStoreUserToStoreUser = JSONStoreUser => StoreUser.FromJSON(JSONStoreUser)

/**
 * Returns requested-/all user(s) from state.data.users.
 * @param userIds {string | string[]}: id of requested users.
 * @param asMap {boolean}: when multiple users are requested, return users as an object mapping users by their id.
 * @param fetchAllEnabled {boolean}: enable fetching of all users, when no user id is passed.
 * @returns {StoreUser | StoreUser[]}
 */
const useNormalizedUsers = (
  userIds = null,
  asMap = false,
  fetchAllEnabled = false
) => useDataStoreItems(
  userIds,
  UserDataStoreSelectors.users,
  UserDataStoreSlice.actions.fetch,
  JSONStoreUserToStoreUser,
  asMap,
  fetchAllEnabled
)

export default useNormalizedUsers
