import { commonHandlers, createReducer } from '../../index'

// each data has to be stored by key
const initialState = {}

/**
 * Creates a reducer for dataStore management.
 * @param dataStoreTypes {{MERGE: string, ADD_ITEM: string, DELETE_ITEM: string, RESET: string}}
 * @param customInitialState {object}: overwrite default initial state.
 * @returns {(function(*=, *): (*))|*}
 */
const createDataStoreReducer = (dataStoreTypes, customInitialState) => createReducer(
  customInitialState || initialState,
  {
    [dataStoreTypes.MERGE]: commonHandlers.deepMergeIntoState('data'),
    [dataStoreTypes.ADD_ITEM]: commonHandlers.addItemToState('id', 'item'),
    [dataStoreTypes.DELETE_ITEM]: commonHandlers.removeItemFromState('id'),
    [dataStoreTypes.RESET]: commonHandlers.resetState(customInitialState || initialState)
  }
)

export default createDataStoreReducer
