/**
 * @typedef {string} SUPPORTED_LOCALES
 * @typedef {string} VALUE_FORMAT_TYPES
 **/

/**
 * @enum {SUPPORTED_LOCALES}
 */
export const SUPPORTED_LOCALES = {
  EN_GB: 'en-GB',
  EN_US: 'en-US',
  FR: 'fr-FR'
}

// you can modify & extend the list of date-, number-, etc value format types to meet the project's requirements
/**
 * @enum {VALUE_FORMAT_TYPES}
 */
export const VALUE_FORMAT_TYPES = {
  DEFAULT_DATE: 'default_date',
  SHORT_DATE: 'short_date',
  DEFAULT_NUMBER: 'default_number',
  TWO_DIGITS_FIXED_FLOAT_NUMBER: 'two_digits_fixed_float_number'
}
