import { all, fork } from 'redux-saga/effects'
import ErrorSaga from './Error'
import UserDataStoreSlice from '../slices/data/UserDataStoreSlice'
import LinkDataStoreSlice from '../slices/data/LinkDataStoreSlice'
import ClickDataStoreSlice from '../slices/data/ClickDataStoreSlice'
import LanguageDataStoreSlice from '../slices/data/LanguageDataStoreSlice'
import ApiKeyDataStoreSlice from '../slices/data/ApiKeyDataStoreSlice'
import GloballyExcludedClickerDataStoreSlice from '../slices/data/GloballyExcludedClickerDataStoreSlice'
import DataStoresSaga from './data/DataStores'
import AuthExperienceSaga from './experiences/Auth'
import UserManagementExperienceSaga from './experiences/UserManagement'
import LinkManagementExperienceSaga from './experiences/LinkManagement'
import ClickManagementExperienceSaga from './experiences/ClickManagement'
import ApiKeyManagement from './experiences/ApiKeyManagement'
import GloballyExcludedClickerManagement from './experiences/GloballyExcludedClickerManagement'

export default function * rootSaga () {
  yield all([
    fork(ErrorSaga),
    fork(UserDataStoreSlice.saga),
    fork(LinkDataStoreSlice.saga),
    fork(ClickDataStoreSlice.saga),
    fork(LanguageDataStoreSlice.saga),
    fork(ApiKeyDataStoreSlice.saga),
    fork(GloballyExcludedClickerDataStoreSlice.saga),
    fork(AuthExperienceSaga),
    fork(DataStoresSaga),
    fork(UserManagementExperienceSaga),
    fork(LinkManagementExperienceSaga),
    fork(ClickManagementExperienceSaga),
    fork(ApiKeyManagement),
    fork(GloballyExcludedClickerManagement)
  ])
}
