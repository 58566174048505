import ClickDataStoreSelectors from '../selectors/data/Clicks'
import ClickDataStoreSlice from '../slices/data/ClickDataStoreSlice'
import StoreClick from '../models/Click'
import useDataStoreItems from './helpers/useDataStoreItems'

const JSONStoreClickToStoreClick = JSONStoreClick => StoreClick.FromJSON(JSONStoreClick)

/**
 * Returns requested-/all click(s) from state.data.clicks.
 * @param clickIds {string | string[]}: id of requested clicks.
 * @param asMap {boolean}: when multiple clicks are requested, return clicks as an object mapping clicks by their id.
 * @param fetchAllEnabled {boolean}: enable fetching of all clicks, when no click id is passed.
 * @returns {StoreClick | StoreClick[]}
 */
const useNormalizedClicks = (
  clickIds = null,
  asMap = false,
  fetchAllEnabled = false
) => useDataStoreItems(
  clickIds,
  ClickDataStoreSelectors.clicks,
  ClickDataStoreSlice.actions.fetch,
  JSONStoreClickToStoreClick,
  asMap,
  fetchAllEnabled
)

export default useNormalizedClicks
