import { put, takeLatest } from 'redux-saga/effects'
import ERROR_TYPES from '../types/Error'
import LogService from '../../modules/errorHandling/services/log'
import MessageActions from '../actions/Messages'
import { AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT, DISPLAYED_MESSAGE_TYPES } from '../../constants/general'
import OLError from '../../modules/errorHandling/models/OLError'

function * handleError (action) {
  let {
    data: {
      errorObject,
      shouldBeLogged,
      shouldBeDisplayed,
      shouldReferenceBeDisplayed,
      shouldBeAutoHidden
    }
  } = action
  shouldBeLogged = typeof shouldBeLogged === 'undefined' ? true : shouldBeLogged // shouldBeLogged is true by default
  shouldBeDisplayed = typeof shouldBeDisplayed === 'undefined' ? true : shouldBeDisplayed // shouldBeDisplayed is true by default
  shouldReferenceBeDisplayed = typeof shouldReferenceBeDisplayed === 'undefined' ? true : shouldReferenceBeDisplayed // shouldReferenceBeDisplayed is true by default
  shouldBeAutoHidden = typeof shouldBeAutoHidden === 'undefined' ? false : shouldBeAutoHidden // shouldBeAutoHidden is false by default

  if (shouldBeLogged) { // log error object with the log service
    LogService.error(errorObject.getLogInfo())
  }

  if (shouldBeDisplayed) { // display a new error message containing the info from errorObject
    const localizationParams = errorObject instanceof OLError
      ? errorObject.getLocalizedMessageParameters(shouldReferenceBeDisplayed)
      : [errorObject?.message]
    yield put(
      MessageActions.add(
        DISPLAYED_MESSAGE_TYPES.ERROR,
        undefined,
        localizationParams,
        shouldBeAutoHidden ? AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT : undefined
      )
    )
  }
}

function * ErrorSaga () {
  yield takeLatest(ERROR_TYPES.TRIGGER, handleError)
}

export default ErrorSaga
