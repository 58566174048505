import { useSelector } from 'react-redux'
import LayoutSelectors from '../selectors/Layout'

/**
 * Get the global loading state from state.ui.layout.loading or just a specific loading state described by a fetch id.
 * @param fetchId {string}: Get the loading state of a smaller unit described by a fetch id.
 * @returns {boolean} state.ui.layout.loading || !!state.ui.layout.fetchIds[fetchId]
 */
const useLoading = (fetchId = '') => {
  const globalLoading = useSelector(LayoutSelectors.loading)
  const fetchIds = useSelector(LayoutSelectors.fetchIds)
  // loading of a specific feature was requested
  if (fetchId) {
    return !!fetchIds[fetchId]
  }
  // global loading state was requested
  return globalLoading
}

export default useLoading
