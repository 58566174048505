const selectUsers = state => state.data.users

const selectUserById = userId => state => state.data.users[userId]

const UserDataStoreSelectors = {
  users: selectUsers,
  userById: selectUserById
}

export default UserDataStoreSelectors
