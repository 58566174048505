import UserService from '../../../services/User'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const UserDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'user',
  UserService,
  RESTModelNormalizationSchemas.user.schema,
  [
    RESTModelNormalizationSchemas.user.entityName
  ]
)

export default UserDataStoreSagaHandlers
