import axios from 'axios'
import { MAIN_HTTP_API_CONFIG } from '../constants/api/mainHttp'

const mainHTTPClient = axios.create({
  baseURL: MAIN_HTTP_API_CONFIG.URL
})

// original auth
let authInterceptor = null

export const authorizeHttpClient = authToken => {
  authInterceptor = mainHTTPClient.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${authToken}`
    return config
  })
}

// unauthorizing all types of auth
export const unAuthorizeHttpClient = () => {
  typeof authInterceptor !== 'undefined' &&
  authInterceptor !== null &&
  mainHTTPClient.interceptors.request.eject(authInterceptor)
}

// TODO: implement general response handling by registering interceptors
export const registerInterceptor = () => {}

export default mainHTTPClient
