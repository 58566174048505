import { put, takeLatest } from 'redux-saga/effects'
import ApiKeyService from '../../../services/ApiKey'
import ErrorActions from '../../actions/Error'
import OLError from '../../../modules/errorHandling/models/OLError'
import API_KEY_MANAGEMENT_EXPERIENCE_TYPES from '../../types/experiences/ApiKeyManagement'
import LayoutActions from '../../actions/Layout'
import MessageActions from '../../actions/Messages'
import { AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT, DISPLAYED_MESSAGE_TYPES } from '../../../constants/general'
import ApiKeyDataStoreSlice from '../../slices/data/ApiKeyDataStoreSlice'

function * createApiKey (action) {
  const fetchId = 'ApiKeyManagementExperienceSaga.createApiKey'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { apiKeyItem, onSuccess } } = action

    const result = yield ApiKeyService.Create(apiKeyItem.toNormalizedRESTModel())
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['CREATE_API_KEY_SUCCESS', 'ApiKey successfully created'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
      onSuccess && onSuccess()
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[ApiKeyManagementExperience->createApiKey]: Failed to create apiKey', 'Failed to create apiKey', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * editApiKey (action) {
  const fetchId = 'ApiKeyManagementExperienceSaga.editApiKey'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { apiKeyItem, onSuccess } } = action

    const result = yield ApiKeyService.Update(apiKeyItem.id, apiKeyItem.toNormalizedRESTModel())
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['EDIT_API_KEY_SUCCESS', 'ApiKey successfully updated'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
      onSuccess && onSuccess()
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[ApiKeyManagementExperience->editApiKey]: Failed to update apiKey', 'Failed to update apiKey', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * deleteApiKey (action) {
  const fetchId = 'ApiKeyManagementExperienceSaga.deleteApiKey'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { apiKeyId } } = action

    const result = yield ApiKeyService.Delete(apiKeyId)
    if (result.status === 200) {
      // delete item from the store
      yield put(
        ApiKeyDataStoreSlice.actions.deleteItem(apiKeyId)
      )
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['DELETE_API_KEY_SUCCESS', 'Api key deleted'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[ApiKeyManagementExperience->deleteApiKey]: Failed to delete apiKey', 'Failed to delete apiKey', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * ApiKeyManagementExperienceSaga () {
  yield takeLatest(API_KEY_MANAGEMENT_EXPERIENCE_TYPES.CREATE, createApiKey)
  yield takeLatest(API_KEY_MANAGEMENT_EXPERIENCE_TYPES.EDIT, editApiKey)
  yield takeLatest(API_KEY_MANAGEMENT_EXPERIENCE_TYPES.DELETE, deleteApiKey)
}

export default ApiKeyManagementExperienceSaga
