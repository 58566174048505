import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import { USER_ROLES } from '../../modules/ac/constants'
import useDataStoreItemDetails from './helpers/useDataStoreItemDetails'

/**
 * Provides access to the roles available inside the application.
 * @returns {{id: string, name: string}[] | { [string]: {id: string, name: string} }}: roles in a displayable form(role
 * names are already translated)
 */
const useRoles = (roleIds = null, asMap = false) => {
  const { t } = useTranslation()

  const mapRoleToReturnedRole = useCallback(
    role => ({
      id: role,
      name: t(`USER_ROLES['${role}']`, role)
    }),
    [t]
  )

  return useDataStoreItemDetails(USER_ROLES, roleIds, asMap, mapRoleToReturnedRole, true)
}

export default useRoles
