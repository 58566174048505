const selectIsAuthenticated = state => state.experiences.auth.isAuthenticated
const selectProfile = state => state.experiences.auth.profile
const selectAuthChecked = state => state.experiences.auth.authenticationChecked
const selectAuthCodePrefix = state => state.experiences.auth.authCodePrefix
const selectAuthCodePrefixFetched = state => state.experiences.auth.authCodePrefixFetched

const AuthExperienceSelectors = {
  isAuthenticated: selectIsAuthenticated,
  profile: selectProfile,
  authChecked: selectAuthChecked,
  authCodePrefix: selectAuthCodePrefix,
  authCodePrefixFetched: selectAuthCodePrefixFetched
}

export default AuthExperienceSelectors
