class StoreProfile {
  constructor (
    id = undefined,
    email,
    password,
    roleId,
    createdAt,
    theme
  ) {
    this.id = id
    this.email = email
    this.password = password
    this.roleId = roleId
    this.role = null
    this.theme = theme
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      email: this.email,
      password: this.password,
      // roleId is read-only
      theme: this.theme
    })
  }

  static FromNormalizedRESTModel ({
    id,
    email,
    roleId,
    theme
  }) {
    return new StoreProfile(
      id,
      email,
      undefined,
      roleId,
      theme
    )
  }

  toJSON () {
    return {
      id: this.id,
      email: this.email,
      password: this.password,
      roleId: this.roleId,
      theme: this.theme
    }
  }

  static FromJSON ({
    id,
    email,
    password,
    roleId,
    theme
  }) {
    return new StoreProfile(
      id,
      email,
      password,
      roleId,
      theme
    )
  }
}

export default StoreProfile
