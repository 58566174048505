import ErrorBoundary from './modules/errorHandling/components/ErrorBoundary'
import { useCallback } from 'react'
import { Provider } from 'react-redux'
import store from './redux/store'
import AccessControlProvider from './modules/ac/components/AccessControlProvider'
import { BrowserRouter } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import Routes from './routes'
import ThemeProvider from './modules/theming/components/ThemeProvider'
import AuthorizeAccessToApp from './components/utilities/AuthorizeAccessToApp'
import DisplayAppCrash from './components/layout/DisplayAppCrash'
import ThemeLocalizationProvider from './modules/theming/components/ThemeLocalizationProvider'
import { IS_DEV_ENV } from './constants/general'
import ApplicationLoader from './components/utilities/ApplicationLoader'

function App () {
  const renderCaughtError = useCallback(
    errorReference => <DisplayAppCrash errorReference={errorReference} />,
    []
  )

  const AppElements = (
    <BrowserRouter>
      <ApplicationLoader>
        <Provider store={store}>
          <AuthorizeAccessToApp />
          <AccessControlProvider>
            <CssBaseline enableColorScheme />
            <ThemeLocalizationProvider>
              <ThemeProvider>
                <Routes />
              </ThemeProvider>
            </ThemeLocalizationProvider>
          </AccessControlProvider>
        </Provider>
      </ApplicationLoader>
    </BrowserRouter>
  )

  return IS_DEV_ENV
    ? AppElements
    : (
      <ErrorBoundary
        name='App.js'
        renderFallbackUI={renderCaughtError}
      >
        {AppElements}
      </ErrorBoundary>
      )
}

export default App
