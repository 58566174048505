import FilterParameter from './FilterParameter'

/**
 * Utility for combining multiple filtering operations into logical expressions.
 * @param type {FilterExpression.EXPRESSION_TYPE} type of the expression.
 * @param args {(FilterParameter | boolean)[]} n arguments combined by the expression. n >= 1.
 */
class FilterExpression {
  constructor (type, ...args) {
    const allowedExpressionTypes = Object.values(FilterExpression.EXPRESSION_TYPE)

    if (!(args.length > 0)) {
      throw new Error('[FilterExpression] Invalid number of arguments. Minimum 1 argument is needed.')
    }

    if (!allowedExpressionTypes.includes(type)) {
      throw new Error(`[FilterExpression] Invalid expression type parameter: ${type}`)
    }

    this.type = type
    this.expressionArguments = args
  }

  toString () {
    return this.expressionArguments.length
      ? `(${this.expressionArguments
        .map(arg => typeof arg === 'object' ? arg.toString() : arg)
        .join(
          ` ${FilterExpression.EXPRESSION_TYPE_SERIALIZATION_MAPPING[this.type]} `
        )})`
      : ''
  }

  toJSON () {
    return {
      type: FilterExpression.EXPRESSION_TYPE_SERIALIZATION_MAPPING[this.type],
      expressionArguments: this.expressionArguments.map(arg => typeof arg === 'object' ? arg.toJSON() : arg)
    }
  }

  static fromJSON (json) {
    return new FilterExpression(
      FilterExpression.EXPRESSION_TYPE_DESERIALIZATION_MAPPING[json.type],
      ...json.expressionArguments.map(
        arg => typeof arg === 'object'
          ? FilterExpression.isJSONValid(arg)
            ? FilterExpression.fromJSON(arg)
            : FilterParameter.isJSONValid(arg)
              ? FilterParameter.fromJSON(arg)
              : arg
          : arg
      )
    )
  }

  static AND (...args) {
    return new FilterExpression(
      FilterExpression.EXPRESSION_TYPE.AND,
      ...args
    )
  }

  static OR (...args) {
    return new FilterExpression(
      FilterExpression.EXPRESSION_TYPE.OR,
      ...args
    )
  }

  static isJSONValid (json) {
    const jsonKeys = Object.keys(json)
    return jsonKeys.length === 2 && jsonKeys.includes('type') && jsonKeys.includes('expressionArguments')
  }
}

FilterExpression.EXPRESSION_TYPE = {
  AND: 'AND',
  OR: 'OR'
}

FilterExpression.EXPRESSION_TYPE_SERIALIZATION_MAPPING = {
  [FilterExpression.EXPRESSION_TYPE.AND]: 'AND',
  [FilterExpression.EXPRESSION_TYPE.OR]: 'OR'
}

FilterExpression.EXPRESSION_TYPE_DESERIALIZATION_MAPPING = {
  [FilterExpression.EXPRESSION_TYPE_SERIALIZATION_MAPPING[FilterExpression.EXPRESSION_TYPE.AND]]: FilterExpression.EXPRESSION_TYPE.AND,
  [FilterExpression.EXPRESSION_TYPE_SERIALIZATION_MAPPING[FilterExpression.EXPRESSION_TYPE.OR]]: FilterExpression.EXPRESSION_TYPE.OR
}

export default FilterExpression
