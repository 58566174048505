import { useDispatch, useSelector } from 'react-redux'
import MessageSelectors from '../../../redux/selectors/Messages'
import Message from './Message'
import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import MessageActions from '../../../redux/actions/Messages'

const Messages = () => {
  const messages = useSelector(MessageSelectors.messages)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleMessageClose = useCallback(messageId => dispatch(
    MessageActions.hide(messageId)
  ), [dispatch])

  return messages.map(({ id, type, text, localizationParams, hideAfterSec }) => (
    <Message
      key={id}
      id={id}
      type={type}
      text={localizationParams ? t(...localizationParams) : text}
      autoHideAfterSec={hideAfterSec}
      onClose={handleMessageClose}
    />
  )) || null
}

export default Messages
