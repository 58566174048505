// Use an error reporting service of your own to implement the logging methods. For ex.: **sentry**, **winston**, **loglevel**, etc...

const info = async (...args) => console.log(...args)

const warning = async (...args) => console.warn(...args)

const error = async (...args) => console.error(...args)

const debug = async (...args) => console.debug(...args)

const trace = async (...args) => console.trace(...args)

const LogService = {
  info,
  warning,
  error,
  debug,
  trace
}

export default LogService
