import useAppBarTitle from './useAppBarTitle'
import useAuthState from './useAuthState'
import useAvailableThemeNamesAsSelectOptions from './useAvailableThemeNamesAsSelectOptions'
import useFramelessModeTurnedOn from './useFramelessModeTurnedOn'
import useLanguagesAsSelectOptions from './useLanguagesAsSelectOptions'
import useLoading from './useLoading'
import useNormalizedApiKeys from './useNormalizedApiKeys'
import useNormalizedClicks from './useNormalizedClicks'
import useNormalizedGloballyExcludedClickers from './useNormalizedGloballyExcludedClickers'
import useNormalizedLanguages from './useNormalizedLanguages'
import useNormalizedLinks from './useNormalizedLinks'
import useNormalizedLinkClicks from './useNormalizedLinkClicks'
import useNormalizedProfile from './useNormalizedProfile'
import useNormalizedUsers from './useNormalizedUsers'
import useApiKeys from './useApiKeys'
import useClicks from './useClicks'
import useGloballyExcludedClickers from './useGloballyExcludedClickers'
import useLinks from './useLinks'
import useProfile from './useProfile'
import useRoles from './useRoles'
import useRolesAsSelectOptions from './useRolesAsSelectOptions'
import useUsers from './useUsers'

// TODO: refactor non-normalized data hooks to use a deNormalizationConfig parameter(look at how useUsers is implemented)
const storeHooks = {
  useAppBarTitle,
  useAuthState,
  useAvailableThemeNamesAsSelectOptions,
  useFramelessModeTurnedOn,
  useLanguagesAsSelectOptions,
  useLoading,
  useNormalizedApiKeys,
  useNormalizedClicks,
  useNormalizedGloballyExcludedClickers,
  useNormalizedLanguages,
  useNormalizedLinks,
  useNormalizedLinkClicks,
  useNormalizedProfile,
  useNormalizedUsers,
  useApiKeys,
  useClicks,
  useGloballyExcludedClickers,
  useLinks,
  useProfile,
  useRoles,
  useRolesAsSelectOptions,
  useUsers
}

export default storeHooks
