import { applyMiddleware, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import reducers from './reducers'
import rootSaga from './sagas'

const sagaMiddleware = createSagaMiddleware()
const bindMiddlewares = middleware => {
  const applyResult = applyMiddleware(...middleware)

  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('@redux-devtools/extension')
    return composeWithDevTools(applyResult)
  }

  return applyResult
}

export function configureStore (initialState = {}) {
  const store = createStore(
    reducers(),
    initialState,
    bindMiddlewares([
      sagaMiddleware
    ])
  )

  sagaMiddleware.run(rootSaga)

  return store
}

const store = configureStore()

export default store
