import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Skeleton from '@mui/material/Skeleton'

const PageLoadingSkeleton = React.memo(
  () => (
    <Card>
      <CardContent>
        <Skeleton animation='wave' />
        <Skeleton animation='wave' />
        <Skeleton animation='wave' />
      </CardContent>
    </Card>
  )
)

export default PageLoadingSkeleton
