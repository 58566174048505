import mainHTTPClient from '../clients/mainHTTPClient'
import { MAIN_HTTP_API_ENDPOINTS } from '../constants/api/mainHttp'

/**
 * Create a new apiKey.
 * @param apiKeyData {object}: ApiKey data.
 * @returns {Promise<AxiosResponse<object>>}: newly created apiKey.
 * @constructor
 */
const Create = async apiKeyData => mainHTTPClient.post(
  MAIN_HTTP_API_ENDPOINTS.API_KEYS,
  apiKeyData
)

/**
 * Update the specified apiKey's data.
 * @param apiKeyId {string}
 * @param apiKeyData {object}
 * @returns {Promise<AxiosResponse<any>>}: updated apiKey data.
 * @constructor
 */
const Update = async (apiKeyId, apiKeyData) => mainHTTPClient.put(
  MAIN_HTTP_API_ENDPOINTS.API_KEY.replace(':id', apiKeyId),
  apiKeyData
)

/**
 * Delete specified apiKey.
 * @param apiKeyId {string}
 * @returns {Promise<AxiosResponse<string>>}: OK in case the deletion was successful.
 * @constructor
 */
const Delete = async apiKeyId => mainHTTPClient.delete(
  MAIN_HTTP_API_ENDPOINTS.API_KEY.replace(':id', apiKeyId)
)

/**
 *
 * Search for apiKeys.
 * @param listMetaData {ListMetaData}
 * @returns {Promise<AxiosResponse<object[]>>}
 * @constructor
 */
const FindMany = async listMetaData => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.API_KEYS,
  {
    ...listMetaData.toAxiosRequestParams()
  }
)

const ApiKeyService = {
  Create,
  Update,
  Delete,
  FindMany
}

export default ApiKeyService
