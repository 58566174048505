import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import i18next from 'i18next'
import { getDateLocaleByLocaleName } from '../../utilities/date'

const ThemeLocalizationProvider = ({ children }) => (
  <LocalizationProvider
    dateAdapter={AdapterDateFns}
    adapterLocale={getDateLocaleByLocaleName(i18next?.language)}
  >
    {children}
  </LocalizationProvider>
)

export default ThemeLocalizationProvider
