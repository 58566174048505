import { useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'
import AuthExperienceActions from '../../../redux/actions/experiences/Auth'

const CheckAuthentication = () => {
  const dispatch = useDispatch()

  useLayoutEffect(
    () => {
      // check if user is authenticated right before the application is firstly rendered
      dispatch(
        AuthExperienceActions.verifyAuthentication()
      )
    },
    [dispatch]
  )

  return null
}

export default CheckAuthentication
