import { commonHandlers, createReducer } from '../../modules/utilities/store'
import LAYOUT_TYPES from '../types/Layout'

const calculateHideLoadingProps = (state, fetchId) => {
  const fetchIds = Object.keys(state.fetchIds)
    .reduce(
      (fetchIds, id) => {
        // delete loading for @fetchId
        if (id !== fetchId) {
          fetchIds[id] = true
        }
        return fetchIds
      },
      {}
    )

  return {
    loading: !!Object.keys(fetchIds).length, // hide the loading indicator only when all fetches are completed
    fetchIds
  }
}

const initialState = {
  appBarTitle: '',
  loading: false,
  drawerMenuOpen: true,
  chatDrawerOpen: false,
  serviceDrawerOpen: false,
  framelessModeTurnedOn: false,
  fetchIds: {}
}

const handlers = {
  [LAYOUT_TYPES.SET_APPBAR_TITLE]: commonHandlers.setValue('appBarTitle'),
  [LAYOUT_TYPES.SET_IS_LOADING]: (state, action) => ({
    ...state,
    ...(
      action.data.isLoading
        ? ({
            loading: true,
            fetchIds: {
              ...state.fetchIds,
              [action.data.fetchId]: true
            }
          }) // if isLoading true is sent, loading is automatically is set to true and fetch identifier is registered
        : calculateHideLoadingProps(state, action.data.fetchId) // else loading is calculated based on whether other fetchIds exist, and fetchId is unregistered
    )
  }),
  [LAYOUT_TYPES.SET_DRAWER_MENU_OPEN]: commonHandlers.setValue('drawerMenuOpen'),
  [LAYOUT_TYPES.SET_CHAT_DRAWER_OPEN]: commonHandlers.setValue('chatDrawerOpen'),
  [LAYOUT_TYPES.SET_SERVICE_DRAWER_OPEN]: commonHandlers.setValue('serviceDrawerOpen'),
  [LAYOUT_TYPES.SET_FRAMELESS_MODE_TURNED_ON]: commonHandlers.setValue('framelessModeTurnedOn')
}

const LayoutStore = createReducer(initialState, handlers)

export default LayoutStore
