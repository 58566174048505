import { schema } from 'normalizr'

// language
const LANGUAGE_ENTITY_NAME = 'languages'
const language = new schema.Entity(LANGUAGE_ENTITY_NAME)

// role
const ROLE_ENTITY_NAME = 'roles'
const role = new schema.Entity(ROLE_ENTITY_NAME)

// user
const USER_ENTITY_NAME = 'users'
const user = new schema.Entity(
  USER_ENTITY_NAME,
  {
    role
  },
  {
    processStrategy: value => ({
      roleId: value.role,
      ...value
    })
  }
)

// link
const LINK_ENTITY_NAME = 'links'
const link = new schema.Entity(
  LINK_ENTITY_NAME,
  {
    generatedByUser: user
  },
  {
    processStrategy: value => ({
      generatedBy: value.generatedByUserId || value.generatedByUser?.id,
      ...value
    })
  }
)

// click
const CLICK_ENTITY_NAME = 'clicks'
const click = new schema.Entity(CLICK_ENTITY_NAME)

// profile
const PROFILE_ENTITY_NAME = 'profiles'
const profile = new schema.Entity(
  PROFILE_ENTITY_NAME,
  {
    language,
    role
  },
  {
    processStrategy: value => ({
      languageId: value.language?.id,
      roleId: value.role,
      ...value
    })
  }
)

// api key
const API_KEY_ENTITY_NAME = 'apiKeys'
const apiKey = new schema.Entity(API_KEY_ENTITY_NAME)

// globallyExcludedClicker
const GLOBALLY_EXCLUDED_CLICKER_ENTITY_NAME = 'globallyExcludedClickers'
const globallyExcludedClicker = new schema.Entity(GLOBALLY_EXCLUDED_CLICKER_ENTITY_NAME)

const RESTModelNormalizationSchemas = {
  language: {
    entityName: LANGUAGE_ENTITY_NAME,
    schema: language
  },
  profile: {
    entityName: PROFILE_ENTITY_NAME,
    schema: profile
  },
  role: {
    entityName: ROLE_ENTITY_NAME,
    schema: role
  },
  user: {
    entityName: USER_ENTITY_NAME,
    schema: user
  },
  link: {
    entityName: LINK_ENTITY_NAME,
    schema: link
  },
  click: {
    entityName: CLICK_ENTITY_NAME,
    schema: click
  },
  apiKey: {
    entityName: API_KEY_ENTITY_NAME,
    schema: apiKey
  },
  globallyExcludedClicker: {
    entityName: GLOBALLY_EXCLUDED_CLICKER_ENTITY_NAME,
    schema: globallyExcludedClicker
  }
}

export default RESTModelNormalizationSchemas
