/**
 * Creates type constants for data store management.
 * @param sliceName {string}: slice name. A unique store id is generated from it.
 * @returns {{FETCH: string, FETCH_RESULT: string, MERGE: string, ADD_ITEM: string, DELETE_ITEM: string, RESET: string}}
 */
const DATA_STORE_TYPES = sliceName => ({
  FETCH: `[${sliceName} -> dataStore] fetch`,
  FETCH_RESULT: `[${sliceName} -> dataStore] fetchResult`,
  MERGE: `[${sliceName} -> dataStore] merge`,
  ADD_ITEM: `[${sliceName} -> dataStore] addItem`,
  DELETE_ITEM: `[${sliceName} -> dataStore] deleteItem`,
  RESET: `[${sliceName} -> dataStore] reset`
})

export default DATA_STORE_TYPES
