import SortParameter from './SortParameter'
import FilterExpression from './FilterExpression'
import qs from 'qs'
import qsUtilities from '../qs'

/**
 * Encapsulates list-related metadata management for pagination, sorting and filtering.
 * @param limit {int} items on page.
 * @param page {int} current page.
 * @param orderBy {SortParameter} sorting metadata.
 * @param filter {FilterExpression} filtering metadata.
 */
class ListMetaData {
  constructor (limit, page, orderBy = [], filter = null) {
    if (!Array.isArray(orderBy) || (orderBy.length && !(orderBy[0] instanceof SortParameter))) {
      throw new Error(`[ListMetaData] Invalid orderBy parameter ${orderBy}`)
    }

    if (filter !== null && !(filter instanceof FilterExpression)) {
      throw new Error(`[ListMetaData] Invalid filter parameter ${filter}`)
    }

    this.limit = limit || ListMetaData.DEFAULT_LIMIT
    this.page = page || ListMetaData.DEFAULT_PAGE
    this.orderBy = orderBy
    this.filter = filter
  }

  toJSON () {
    return {
      limit: this.limit,
      offset: this.page,
      order_by: SortParameter.SerializeSortParameterCollectionToJSON(this.orderBy),
      filter: this.filter.toJSON()
    }
  }

  static fromJSON (json) {
    return new ListMetaData(
      json.limit,
      json.offset,
      SortParameter.SortParameterCollectionFromJSON(json.order_by),
      FilterExpression.fromJSON(json.filter)
    )
  }

  toRequestParameterString () {
    return qs.stringify(
      this.toRequestParameter()
    )
  }

  toRequestParameter () {
    return ({
      limit: this.limit,
      offset: this.page,
      order_by: SortParameter.SerializeSortParameterCollectionToRequestParam(this.orderBy),
      filter: this.filter?.toJSON()
    })
  }

  static ParseFromRequestParameter (requestParameter) {
    const parsedRequestParameter = qs.parse(
      requestParameter,
      qsUtilities.parsePrimitiveValuesOptions
    )

    return new ListMetaData(
      parsedRequestParameter.limit,
      parsedRequestParameter.offset,
      parsedRequestParameter.order_by
        ? SortParameter.SortParameterCollectionFromRequestParam(parsedRequestParameter.order_by)
        : undefined,
      parsedRequestParameter.filter
        ? FilterExpression.fromJSON(parsedRequestParameter.filter)
        : undefined
    )
  }

  toAxiosRequestParams () {
    return ({
      params: this.toRequestParameter()
    })
  }
}

ListMetaData.DEFAULT_LIMIT = 10
ListMetaData.NO_LIMIT = -1 // we signal to the api, that we want all users by passing NO_LIMIT
ListMetaData.DEFAULT_PAGE = 0

export default ListMetaData
