import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import GloballyExcludedClickerDataStoreSagaHandlers from '../../sagas/data/GloballyExcludedClickerDataStore'

const sliceName = 'globallyExcludedClickersStore'

const GloballyExcludedClickerDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  GloballyExcludedClickerDataStoreSagaHandlers
)

export default GloballyExcludedClickerDataStoreSlice
